.panel__sidebar__importacao__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
  -webkit-transition: 0.3s background-color;
  -o-transition: 0.3s background-color;
  transition: 0.3s background-color;
}

.panel__sidebar__importacao__item+.panel__sidebar__importacao__item {
  border-top: 0.5px solid #e8e8e8;
}

.panel__sidebar__importacao__item:hover,
.panel__sidebar__importacao__item.is-selected {
  background-color: rgba(180, 199, 227, 0.23);
}

.list-btn-importacao {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  margin: 20px 0 10px 0;
}
.list-btn-importacao > *:not(:first-child) {
  margin-left: 20px; }

.list-erros {
  padding: 10px 0px 10px 0px;
}