.icon-container {
  position: absolute;
  right: 10px;
  top: calc(100% - 33px);
}
.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader::after, .loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #528ce3 #528ce3 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.form-group.has-warning input, .form-group.has-warning select, .form-group.has-warning textarea {
  border: 1px solid #ffc107;
  background-color: #fafdec;
}
.form-group.has-warning .form-tip {
  display: block;
}

.form-label-warning {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: #43491a;
  display: block;
  margin-bottom: 5px;
}

.tarefas-button-danger {
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(250, 55, 0, 0.5);
  opacity: 0.7;
  background-color: rgba(227, 82, 82, 0.1);
  color: black;
}
.tarefas-button-danger:hover {
  color: #ff0f07;
  background-color: white;
}

.tarefas-button-warning {
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e7dc3ce0;
  opacity: 0.7;
  background-color: rgba(227, 225, 82, 0.1);
  color: black;
}

.yes:hover {
  color: #ffc107;
  background-color: black;
}

.no:hover {
  color: #ffc107;
  background-color: black;
}

.updates-count {
  background: #fff;
  color: #528ce3;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  text-align: left;
  font-weight: bold;
  font-size: 11px;
}

.button-task-comments {
  width: auto;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 0px 0px 0px 10px;
  color: #fff;
  background-color: #528ce3;
}
.button-task-comments-light {
  width: auto;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 0px 0px 0px 0px;
  border: solid 1px #528ce3;
  color: #528ce3;
  background-color: transparent;
}

.padding-task-visualizacao {
  padding-bottom: 40px;
}

.list-btn-tasks {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: right;
}

.list-btn-tasks > *:not(.--danger) {
  margin-left: 5px; }

.table-task {
  padding-left: 3px;
  margin: 4px;
}

.table-task-box-value {
  padding-left: 10px;
}

.comentario .table-task-box-value {
  padding-left: 0px;
}

.table-items__mobile-value-tarefas {
  width: 249px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;  
}

.table-items-expanded__box-item-tarefas {
  max-width: 300px;
}

.unity-infos__item-tarefas {
  border: 1;
  margin-bottom: 8px;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: rgba(180, 199, 227, 0.23);
  font-size: 14px;
  font-weight: 300;
  line-height: 2.57;
  color: #393042; }
  .unity-infos__item-tarefas strong {
    font-weight: 500; }

.column-activity-log {
  font-weight: 300;
  line-height: 1.57;  
  display: flex; 
  align-items: center
}

.column-activity-log i {
  margin-right: 4px;
  font-size: 16px;
}
.column-activity-log img {
  margin-right: 8px;    
}

.sm {
  font-size: 13px;  
}
    

.column-activity-log-what {
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis; 
}

.column-activity-log-who {
  top: 10px;
  width: 190px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis; 
  align-items: center
}

.form-table-icon {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  left: 0;
}

.table-items__data_task {
  padding: 8px 16px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 1.57;
}

/* ========================================================================== 
   slide-pane
   ========================================================================== */
.slide-pane {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0,0,0,0.5);
  transition: transform 0.5s;
  will-change: transform;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
  transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0);
  z-index: 99;
}
.slide-pane__overlay.overlay-after-open {
  background-color: rgba(0,0,0,0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
  background-color: rgba(0,0,0,0);
}
.slide-pane__header {
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: #ebebeb;
  height: 64px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
.slide-pane__close {
  margin-left: 24px;
  padding: 16px;
  opacity: 0.7;
  cursor: pointer;
}
.slide-pane__close svg {
  width: 12px;
  padding: 0;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 24px 32px;
  flex: 1 1 auto;
}
.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}

/* ========================================================================== 
   FIM slide-pane
   ========================================================================== */

.form-tarefas .status,
.form-tarefas .tipo {
  font-size: 14px; }

.form-tarefas .tipo {
  margin-bottom: 5px; }

.cards {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 15px; }

.card-agrupamento { flex-grow: 1.5; flex-basis: 0  }
.card-status { flex-grow: 1.5; flex-basis: 0; cursor: default; }

@media (max-width: 1024.98px) {
  .card-agrupamento { flex-grow: 1; flex-basis: 50% }
  .cards { gap: 1.5rem }
}

.card {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #fff;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  padding: 15px; }
.card:hover {
  z-index: 2;
  border: 1px solid rgba(180, 199, 227, 0.35);
  -webkit-box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
  box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35); }

.card-agrupamento .card:not(:first-of-type) {
  margin-top: 1.5rem; }

.card-componente {
  width: 100%;
  position: relative;
  margin: 12px 0;
  padding-left: 35px;
  padding-right: 16px; }
.card-componente .titulo {
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #92959d;
  cursor: default; }
.card-componente .icon {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 16px;
  color: #b4c7e3; }
.card-componente label {
  display: block;
  margin-bottom: 5px; }
.card-componente .valor {
  font-size: 14px;
  line-height: 1.57;
  color: #393042; }

.botoes-transicao {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }
.botoes-transicao .button {
  min-width: max-content; }
.botoes-transicao button:not(:last-of-type) {
  margin-right: 1.5rem; }

@media (max-width: 575.98px){
  .botoes-transicao {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;  }
  .botoes-transicao button:not(:last-of-type) {
    margin-bottom: 1.5rem;  }
}

.assistence-end-task {  
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 5px;
  padding: 12px 20px;  
  background-color: #70C968;
  font-size: 14px;
  line-height: 1;
  color: #ffffff; }
  .assistence-end__time-task {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 0 !important;
    padding: 6px 10px;
    border-radius: 4px;
    border: solid 1px #70C968;
    background-color: #70C968;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: #ffffff; }
    .assistence-end__time-task > i {
      margin: 0 4px 0 0;
      font-size: 18px;
      color: #ffffff; }
      .assistence-end__time-task > i:before {
        margin: 0; }

.atrasado {
  background-color: #ff5a44;
  border: solid 1px #ff5a44;
  background-color: #ff5a44; }

.concluida {
  opacity: 0.4;
  height: 28px; }

.button.--alert {
  font-size: 14px;
  line-height: 1.57;
  text-decoration: none;
  color: #F0AD4E;
  background-color: transparent;
  border-radius: 5px;
  border: solid 1px #F0AD4E;
  padding: 1.05rem;
  min-width: 115px;
  display: table;
  text-align: center; }

.alerta {
  cursor: default;
  min-width: 115px !important;
  padding: 1.05rem !important; }
.alerta .icon {
  font-size: 18px;
  margin: 0; }
.alerta:last-child {
  margin-left: 5px; }

.tooltip-alerta {  max-width: 300px; }
.tooltip-alerta div {
  font-size: 14px;
  margin-bottom: 3px;
  padding-bottom: 3px;
  border-bottom: solid 1px rgb(153,153,153); }
.tooltip-alerta ul {
  list-style: inside;
  font-size: 13px;
  line-height: 1.25; }

.card-componente .icon.comentarios {
  top: -4px; }

.link-prontuario {
  top: -4px;
  margin-left: 5px; }
  .link-prontuario:hover {
    color: #528ce3; }
  .link-prontuario.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.tooltip-atividades {  max-width: 300px; }
.tooltip-atividades div {
  font-size: 14px;
  margin-bottom: 3px;
  padding-bottom: 3px;
  border-bottom: solid 1px rgb(153,153,153); }
.tooltip-atividades-header {  max-width: 500px; }

.componente-select-acao {
  display: grid;
  grid-template-columns: auto max-content max-content max-content;
}

.button-light-select-acao {
  width: auto;
  background-color: rgba(82, 140, 227, 0.1);
  color: #528ce3;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 0px 0px 5px 5px;
  font-weight: 500; }
  .button-light-select-acao.is-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.button-select-acao {
  width: auto;
  background-color: #528ce3;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 0px 0px 5px 0px; }
.button-select-acao.is-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5; }

.tooltip-descricao {
  width: 300px;
  height: auto;
  max-height: 300px;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(180, 199, 227, 0.35);
          box-shadow: 0px 0px 18px 0px rgba(180, 199, 227, 0.35); }

.mais-tarefas {
  background-color: rgba(82, 140, 227, 0.1);
  color: #528ce3;
  border-radius: 10px;
  min-height: 30px; }

.atividades-titulo {
  opacity: 0.7; 
  height: 21px; }

.tooltip-nome { max-width: 300px !important; }

.grid-foto {
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 5px; }
.grid-foto img {
  border-radius: 5px;
  width: 40px;
  height: 40px; }
.grid-foto .nome {
  align-self: center;  }

.container-item-auto-complete-tarefa {
  display: grid;  
} 
.container-item-auto-complete-tarefa span {
  font-weight: 600;
}
.segunda-linha-auto-complete-tarefa {
  display: grid;  
}
.segunda-linha-auto-complete-tarefa span {
  font-size: 11px;
  color: #616161;
  font-style: italic;
}

.box-task__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 65px;
  border-bottom: 1px solid #e8e8e8;
  padding-left: 60px;
  min-width: 1145px; 
}

.checkbox input + label {
  line-height: 1.15;
}

.table-items__bcolor.urgencia-tarefas {
  display: inline-block;
  width: auto !important;
  padding: 6px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1; }

.abas {
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 8px 18px 0 rgba(180, 199, 227, 0.35);
  z-index: 3;
}

.filtros {
  padding: 0 12px;
  background-color: #fff;
  box-shadow: 0 8px 18px 0 rgba(180, 199, 227, 0.35);
  z-index: 3;
}

@media (max-width: 767.98px) {
  .abas .header-page-bar .header-page-nav {
    margin-top: 12px; }
}

@media (max-width: 1024.98px) {
  .abas .tab {
    overflow-x: auto;
    padding-bottom: 0;
  }
}

.table-items__bcolor.paciente-tarefas {
  display: inline-block;
  width: auto !important;
  padding: 6px 10px;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1;
  background-color: rgba(180, 199, 227, 0.23);
  max-width: 249px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.observacoes-agendamento {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.card-componente .telefones-do-paciente {
  width: 100%;
  position: relative;
  margin: 2px 0;
  padding-left: 20px;
  left: -6px; }
.card-componente .telefones-do-paciente .titulo {
  font-size: 14px;
  line-height: 1.83;
  letter-spacing: 1px;
  text-transform: none;
  cursor: pointer;
  color: #92959d; }
  .card-componente .telefones-do-paciente .titulo:hover {
    color: #528ce3; }
.card-componente .telefones-do-paciente label {
  display: block;
  margin-bottom: 0; }
.card-componente .telefones-do-paciente .valor {
  font-size: 13px;
  line-height: 1.83;
  padding-left: 6px;
  color: #393042; }
.card-componente .telefones-do-paciente .titulo-acao {
  display: grid;
  grid-template-columns: max-content max-content; }

.separator__tooltip_paciente {
  margin: 1px 0 !important;
}

.link-agendamento {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-agendamento span {
  display: flex;
  align-items: center;
}

.link {
  margin-left: 5px;
  padding: 4px !important;
}