@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?14899420');
  src: url('../font/fontello.eot?14899420#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?14899420') format('woff2'),
       url('../font/fontello.woff?14899420') format('woff'),
       url('../font/fontello.ttf?14899420') format('truetype'),
       url('../font/fontello.svg?14899420#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?14899420#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-videocam:before { content: '\e701'; } /* '' */
.icon-check:before { content: '\e795'; } /* '' */
.icon-history:before { content: '\e796'; } /* '' */
.icon-attention-circled:before { content: '\e798'; } /* '' */
.icon-chart-line:before { content: '\e799'; } /* '' */
.icon-agenda-novo:before { content: '\e800'; } /* '' */
.icon-lock-open:before { content: '\e800'; } /* '' */
.icon-agenda-profissional:before { content: '\e801'; } /* '' */
.icon-agenda:before { content: '\e802'; } /* '' */
.icon-edit:before { content: '\e803'; } /* '' */
.icon-pacientes:before { content: '\e804'; } /* '' */
.icon-sala:before { content: '\e805'; } /* '' */
.icon-confirmacao:before { content: '\e806'; } /* '' */
.icon-arrow-up:before { content: '\e807'; } /* '' */
.icon-arrow-down:before { content: '\e808'; } /* '' */
.icon-more:before { content: '\e809'; } /* '' */
.icon-info:before { content: '\e80a'; } /* '' */
.icon-arrow-left:before { content: '\e80b'; } /* '' */
.icon-arrow-right:before { content: '\e80c'; } /* '' */
.icon-search:before { content: '\e80d'; } /* '' */
.icon-mail:before { content: '\e80e'; } /* '' */
.icon-especialidade:before { content: '\e80f'; } /* '' */
.icon-select:before { content: '\e810'; } /* '' */
.icon-empresa:before { content: '\e811'; } /* '' */
.icon-checked:before { content: '\e812'; } /* '' */
.icon-down:before { content: '\e813'; } /* '' */
.icon-remove:before { content: '\e814'; } /* '' */
.icon-up:before { content: '\e815'; } /* '' */
.icon-date:before { content: '\e816'; } /* '' */
.icon-card:before { content: '\e817'; } /* '' */
.icon-doc:before { content: '\e818'; } /* '' */
.icon-doctor:before { content: '\e819'; } /* '' */
.icon-pencil:before { content: '\e81a'; } /* '' */
.icon-name:before { content: '\e81b'; } /* '' */
.icon-phone:before { content: '\e81c'; } /* '' */
.icon-smartphone:before { content: '\e81d'; } /* '' */
.icon-hospital:before { content: '\e81e'; } /* '' */
.icon-plus:before { content: '\e81f'; } /* '' */
.icon-close:before { content: '\e820'; } /* '' */
.icon-minus:before { content: '\e821'; } /* '' */
.icon-clock:before { content: '\e822'; } /* '' */
.icon-camera-outline:before { content: '\e823'; } /* '' */
.icon-block:before { content: '\e824'; } /* '' */
.icon-ok-circled:before { content: '\e825'; } /* '' */
.icon-undo:before { content: '\e826'; } /* '' */
.icon-forward:before { content: '\e827'; } /* '' */
.icon-print:before { content: '\e828'; } /* '' */
.icon-lamp:before { content: '\e829'; } /* '' */
.icon-shuffle:before { content: '\e82a'; } /* '' */
.icon-filter:before { content: '\e83b'; } /* '' */
.icon-attention:before { content: '\e898'; } /* '' */
.icon-folder-empty:before { content: '\f069'; } /* '' */
.icon-copy:before { content: '\f0c5'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-estabelecimento-de-saude:before { content: '\f0f8'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-sort-alt-down:before { content: '\f161'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-eyedropper:before { content: '\f1fb'; } /* '' */
.icon-birthday:before { content: '\f1fd'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-heartbeat:before { content: '\f21e'; } /* '' */
