.dashboard-item-icon:after {
  background-color: transparent;
}

.menu-account__drop__body {
  padding: 10px;
}

.menu__drop {
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu__drop .menu__drop-link {
  font-size: 14px;
  font-weight: 500;
}

.menu__link:hover,
.menu__drop .menu__drop-link:hover {
  color: #528ce3;
}

.breadcrumb {
  margin-bottom: 15px;
}

.header-page-form {
  margin-top: 15px;
}

.form fieldset {
  padding: 20px;
}

.user-paciente {
  margin-top: 0;
  padding: 20px 20px;
}

@media (min-width: 1025px) {
  .page-agendamento-passo-2 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'description agendamento';
  }
}

@media (min-width: 768px) {
  .page-agendamento-passo-3 {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-areas: 'description agendamento';
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .page-horarios {
    margin-left: 1.8em;
    border-left: solid 1px rgba(180, 199, 227, 0.23);
  }
}

@media (max-width: 767.98px) {
  .page-horarios {
    margin-top: 1.8em;
    border-left: solid 1px rgba(180, 199, 227, 0.23);
  }
}

@media (min-width: 1025px) {
  .page-paciente {
    padding-right: 2.0em;
    border-right: solid 1px rgba(180, 199, 227, 0.23)
  }
}

@media (min-width: 1025px) {
  .renderizar-convenio {
    margin-left: 2.0em;
  }
}

@media (max-width: 1024.98px) {
  .renderizar-convenio {
    margin-top: 2.5em;
  }
}

.form-item {
  background-color: rgba(82, 140, 227, 0.2);
  border-radius: 5px;
}

.form-item-passo-3 {
  background-color: rgba(82, 140, 227, 0.2);
  border-radius: 5px;
  padding: 9px;
  margin-bottom: 10px;
}

.form-item__choice {
  position: relative;
  font-size: 14px;
  color: #393042;
  padding: 5px 10px;
}

.form-item__choice-passo-3 {
  position: relative;
  font-size: 14px;
  color: #393042;
  padding: 5px;
}

.form-item__choice-passo-3.profissional {
  font-weight: bold;
  font-size: 16px;
}

.buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.buttons>*:not(:first-child) {
  margin-left: 20px;
}

.buttons .--light {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  line-height: 1.57;
  text-decoration: none;
  text-align: center;
  color: #528ce3;
  background-color: transparent;
  border-radius: 5px;
  border: solid 1px #528ce3;
  padding: 8px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.buttons-modal {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.buttons-modal > *:not(:first-child) {
  margin-left: 20px;
}

@media (max-width: 1024.98px) {
  .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.nao-ha-horarios {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.buttons-unidade {
  width: auto;
  height: auto;
  border-radius: 5px;
  border: solid 1px #e2e2e2;
  background-color: #f3f3f3;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #d5d5d5;
  display: inline-block;
  margin-right: 4.3px;
  margin-bottom: 4.3px;
  margin-top: 4.3px;
  padding: 3px;
}

.renderizar-horarios {
  display: -webkit-box;
  display: -ms-flexbox;
  border-radius: 4px;
  margin-top: 15px;
  background-color: #fff;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border: 1px solid rgba(180, 199, 227, 0.35);
  -webkit-box-shadow: 0 0 10px -3px rgba(180, 199, 227, 0.35);
  box-shadow: 0 0 10px -3px rgba(180, 199, 227, 0.35);
}

.renderizar-horarios:hover {
  border: 1px solid rgba(180, 199, 227, 0.35);
  -webkit-box-shadow: 0 12px 12px 0 rgba(180, 199, 227, 0.35);
  box-shadow: 0 12px 12px 0 rgba(180, 199, 227, 0.35);
}

.image-paciente {
  width: 115px;
  margin: 0 -50% 0 50%;
  transform: translate(-50%)
}

.image-paciente img {
  border-radius: 5px;
  width: 115px;
  height: 115px
}

@media (max-width: 767.98px) {
  .box-hours-free__item {
    padding: 0 0 10px 0;
  }
}

.box-hours-free__item .list-buttom-hours.unidade {
  width: auto;
  height: auto;
  padding: 7px;
  margin: 2px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  color: #d5d5d5;
  display: inline-block;
}

.box-hours-free__item .list-buttom-hours button.orange {
  background-color: rgba(250, 140, 22, 0.15);
  color: #fa8c16;
  border-color: #fa8c16;
}

.box-hours-free__item .list-buttom-hours button.green-right {
  background-color: rgba(82, 196, 26, 0.15);
  color: #52c41a;
  border-color: #52c41a;
}

.box-hours-free__item h4 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #393042;
}

.excluir:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font: 0/0 a;
  content: '\e814';
  font-family: fontello;
  font-size: 17px;
  color: #528ce3;
}

.excluir:hover {
  background-color: rgba(82, 140, 227, 0.15);
  border-radius: 5px;
}

.nav-route-agendamento {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 26px 0;
}

.nav-route-back-agendamento {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0;
  flex: 0 0 0;
}

.nav-route-agendamento.justify-content-center {
  margin: 0 0 5px 0;
}

.nav-route-agendamento.margem-zero {
  margin: 0 0;
}

.nav-route-agendamento-name {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  line-height: 1.71;
  color: #393042;
  background: none;
}

.nav-route-agendamento-name::before {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: rgba(57, 48, 66, 0.03);
  background-image: url(../img/arrow-back.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.nav-convenio {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

.nav-step {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #b4c7e3;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.nav-step .active {
  color: #393042;
  margin-right: 5px;
}

.nav-convenio > p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #393042;
  margin-left: 30px;
}

@media (max-width: 1024.98px) {
  .nav-route-back-agendamento {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0;
    flex: 0 0 0;
  }
}

@media (max-width: 767.98px) {
  .nav-route-back-agendamento {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0;
    flex: 0 0 0;
  }

  .nav-route-agendamento-name span {
    display: none;
  }
}

.alinha-botao {
  margin-top: 24px;
  max-height: 49px;
}

@media (max-width: 767.98px) {
  .alinha-botao {
    margin-top: 0;
    max-height: 49px;
  }
}

.button-wizard {
  background-color: transparent;
}

.button-wizard.has-error {
  margin-top: 0;
  float: right;
  top: -25%;
}

.button-wizard:before {
  content: '';
  display: none;
  width: 18px;
  height: 18px;
  background: url(../img/oval.svg) no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.button-wizard.--primary {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  line-height: 1.57;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: #528ce3;
  border-radius: 5px;
  padding: 14px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-wizard.--w100 {
  width: 100%;
}

.button-wizard.--plus {
  position: relative;
  padding-right: 60px;
}

.button-wizard.--plus:after {
  content: '';
  position: absolute;
  top: 8px;
  right: 14px;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #4078cc url(../img/icon-plus.svg) no-repeat center;
}

.button-wizard:disabled,
.button-wizard[disabled],
.button-wizard.is-disabled,
.button-wizard.is-loading {
  opacity: 0.5;
  pointer-events: none;
}

.graficos {
  background-color: #fff;
  padding: 40px 0;
  border-radius: 4px;
  width: 100%;
  max-width: 1510px;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 11px;
}

.graficos-de-internacoes {
  background-color: #fff;
  padding: 40px 0;
  border-radius: 4px;
  width: 100%;
  max-width: 1510px;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 11px;
}

.tooltip-personalizado {
  background-color: #fff;
  border: #f0f2f5 1px solid;
  padding: 8px;
  font-size: 14px;
}

.tooltip-personalizado p {
  margin-bottom: 6px;
}

.tooltip-personalizado .data {
  font-weight: 700;
}

.relatorios .ant-divider-horizontal {
  margin-top: 0;
  margin-bottom: 16px;
}

.recharts-legend-wrapper {
  font-size: 14px;
}

.relatorios .botao-download {
  font-size: 12px;
  align-self: center;
  background-color: transparent;
  color: #1890ff;
}

.relatorios .action-bar {
  display: flex;
  justify-content: space-between;
}

.relatorios .separador-de-profissao-e-profissional-de-saude {
  text-align: center;
  padding-top: 30px;
  font-size: 14px;
  margin-bottom: 15px;
}

.title-adicionar {
  margin-bottom: 0.75rem;
}

/* Pacientes
   ========================================================================== */
.botao-editar-paciente {
  font-size: 12px;
  align-self: center;
  background-color: transparent;
  color: #1890ff;
}

.page-pacientes-lista .header-page-tools .user-paciente .button {
  margin-left: auto;
}

@media (max-width: 575.98px) {
  .page-pacientes-lista .header-page-tools .user-paciente .button {
    min-width: 100%;
    margin-top: 1.5rem;
  }
}

/* ========================================================================== */


/* Legenda
   ========================================================================== */
.legenda-das-unidades {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  color: #92959d;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.38;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #fff;
  padding: 8px 14px;
}

.legenda-das-unidades .item-da-legenda {
  display: grid;
  grid-template-columns: 25px auto 25px auto;
  gap: 8px;
  align-items: center;
}

.legenda-das-unidades .item-da-legenda .cor {
  width: 15px;
  height: 15px;
}

/* ========================================================================== */


/* Agenda
   ========================================================================== */
.mensagens-do-calendario {
  font-size: 14px;
  text-align: center;
}

.user-doctor__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 1219px) {
  .user-doctor__name {
    width: 210px;
  }
}

@media (max-width: 1218px) {
  .user-doctor__name {
    width: 150px;
  }
}

.container-nenhum-profissional-encontrado {
  padding: 8px;
  margin-bottom: 0;
  min-height: 66px;
  border-top-color: rgb(235, 237, 240);
  border-top-style: solid;
  border-top-width: 1px;
  color: rgba(0, 0, 0, .45);
  font-size: 12px;
}

.date-period__content input {
  font-size: 12px;
  padding: 15px;
  width: 180px;
  height: 15px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  background-color: #00000008;
  border: 1px solid transparent;
  text-align: center;
}

.date-period__content {
  font-size: 14px;
  color: #528ce3;
  padding: 3px 5px;
  background-color: #00000008;
}

.box-agenda__novo_atendimento {
  position: absolute;
  width: 17px;
  height: 60px;
  top: 0;
  right: 0;
}

.box-agenda__drop__cancel {
  color: #ff7675;
  left: 17px;
}

.box-agenda__drop__description {
  height: 100%;
  max-height: 260px;
}

.box-agenda__drop__description * + p {
  margin-top: 5px;
}

.box-agenda__drop__time {
  margin: 10px 0 10px 0;
}

.box-agenda__drop__body {
  margin-top: 15px;
}

.box-agenda__drop.--left {
  right: auto;
  left: 5px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.box-agenda__event-multiple__time,
.box-agenda__event-multiple__local {
  width: 100%;
  font-size: 12px;
}

.box-agenda__block-event {
  z-index: 1
}

.box-agenda__event-multiple {
  z-index: 2
}

.box-agenda__unity {
  z-index: 3
}

.box-agenda__event {
  z-index: 2;
}

.box-agenda__event__mouse-over {
  z-index: 3;
}

.box-agenda__disable-event-feriado {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F0FFFF;
  cursor: default;
}

/* ========================================================================== */


/* Agendamento
   ========================================================================== */
.container-item-auto-complete-paciente {
  display: grid;
  grid-template-columns: 4fr 2fr;
}

.container-item-auto-complete-paciente .data-de-nascimento {
  text-align: right;
  font-size: 12px;
}

.proximo-horario {
  padding-top: 28px;
}

.segunda-linha-auto-complete-pacientes {
  display: grid;
  grid-template-columns: 4fr 3fr;
}

.segunda-linha-auto-complete-pacientes .unidade-do-paciente {
  text-align: right;
}

.container-item-auto-complete-paciente .data-de-nascimento,
.segunda-linha-auto-complete-pacientes span {
  font-size: 12px;
  color: #616161;
  font-style: italic;
}

.container-item-auto-complete-paciente .nome span {
  font-weight: 600;
}

.proximo-mes {
  text-align: center;
  margin-top: 16px;
  padding-bottom: 28px;
}

.page-configuracoes-agendamento .header-agendamento {
  margin-bottom: 15px;
}

/* Botao Horario Livre */
.button-hours-free {
  background-color: transparent;
}

.button-hours-free.has-error {
  margin-top: 0;
  float: right;
  top: -25%;
}

.button-hours-free:before {
  content: '';
  display: none;
  width: 18px;
  height: 18px;
  background: url(../img/oval.svg) no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.button-hours-free.--primary {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1.57;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background-color: #528ce3;
  border-radius: 5px;
  padding: 14px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-hours-free.--w100 {
  width: 100%;
}

.button-hours-free {
  position: relative;
}

.button-hours-free:disabled,
.button-hours-free[disabled],
.button-hours-free.is-disabled,
.button-hours-free.is-loading {
  opacity: 0.5;
  pointer-events: none;
}

/* ========================================================================== */


.page-alterar-senha .header-page-form {
  margin-top: 15px;
}

.relatorios .botao-gerar {
  height: 51px;
  min-width: 160px;
}

.list-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 20px 0 10px 0;
}

.user-paciente-agenda-modal {
  margin-top: 0;
  border-radius: 4px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.user-paciente-agenda-modal__name {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #393042;
}

.user-paciente-agenda-modal__info {
  display: grid;
}

.user-paciente-agenda-modal__info p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
}

.user-paciente-agenda-modal .foto-do-paciente img {
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-right: 10px;
  border-radius: 4px;
  width: 115px;
  height: 105px;
}

@media (max-width: 1024.98px) {
  .user-paciente-agenda-modal__info {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
}

@media (max-width: 767.98px) {
  .user-paciente-agenda-modal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .user-paciente-agenda-modal .foto-do-paciente img {
    margin: 0 -50% 0 50%;
    transform: translate(-50%);
    margin-bottom: 10px;
  }

  .user-paciente-agenda-modal__info p {
    margin-bottom: 10px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 2px;
  width: 700px;
  overflow-y: auto;
  max-height: 95%;
}

@media (max-width: 1024.98px) {
  .modal {
    width: 75% !important;
    max-height: 90%;
    overflow-y: scroll;
  }
}

@media (max-width: 767.98px) {
  .modal {
    width: 100% !important;
    max-height: 100%;
    overflow-x: scroll;
    margin: 20px 0 20px;
  }
}

/*textarea*/
.form-group > textarea {
  height: 150px;
  margin: 0;
}

/* itens lista Tabela */
.table-items__row,
.table-items-expanded__row,
.table-items-expanded__collapsable-cols,
.table-items-expanded__collapsable-content {
  min-height: 60px;
}

/* itens lista Tabela */
.table-items__row.is-head,
.table-items-expanded__row.is-head {
  min-height: 0;
}

.table-items.--secondary .table-items__row .table-items-expanded__row,
.table-items-expanded__collapsable-cols,
.table-items-expanded__collapsable-content {
  border: 1px solid rgba(180, 199, 227, 0.23);
}

.table-items.--secondary .table-items__row.is-head .table-items-expanded__row.is-head {
  border: 0;
}

.table-items__row.linha-com-warning {
  position: relative;
}

.table-items__row.linha-com-warning::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #ffe58f;
  width: 5px;
  /* Largura da marcação */
  background-color: #fffbe6;
  /* Laranja mais suave */
}

.table-items__row.linha-com-error {
  position: relative;
}

.table-items__row.linha-com-error::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #fa360034;
  width: 5px;
  /* Largura da marcação */
  background-color: rgba(250, 55, 0, 0.1);
  /* Laranja mais suave */
}


/* Popover DatePicker */
.react-datepicker-popper {
  z-index: 99;
}

.react-datepicker__current-month {
  font-size: 15px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  font-size: 12px;
}

/* Fim Popover DatePicker */


.user-paciente-agenda img {
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-right: 10px;
  border-radius: 10px;
  width: 115px;
  height: 115px;
}

.react-datepicker .react-datepicker__header {
  font-size: 14px;
}

.react-datepicker__month-wrapper {
  font-size: 13px;
  line-height: 1.57;
  color: #393042;
}

.react-datepicker__month-wrapper:hover {
  background-color: unset !important;
}

.react-datepicker__month-wrapper--selected {
  background-color: unset !important;
  color: #528ce3 !important;
}

.react-datepicker__month-wrapper--selected:hover {
  background-color: unset !important;
}

.react-datepicker__year-wrapper {
  font-size: 13px;
  line-height: 1.57;
  color: #393042;
}

.react-datepicker__year-wrapper:hover {
  background-color: unset !important;
}

.react-datepicker__year-wrapper--selected {
  background-color: unset !important;
  color: #528ce3 !important;
}

.react-datepicker__year-wrapper--selected:hover {
  background-color: unset !important;
}

.form-error {
  font-size: 12px;
  font-weight: 300;
  color: #e74c3c;
  margin-top: 10px;
  display: block;
}

input[type='file'] {
  display: none;
}

.form-select {
  position: relative;
  padding: 0;
  margin-top: 0;
}

/* @media (max-width: 1024.98px) {
  .table-items__row.is-head {
    border: 0;
    display: none !important; }
} */

.form-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-table .form-table-group {
  min-width: 250px;
}

.form-table .form-table-image {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
}

@media (min-width: 1025px) {
  .form-table .form-table-image:hover .form-table-image-tools {
    opacity: 1;
    visibility: visible;
  }
}

.form-table .form-table-image-tools {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  background-color: rgba(57, 48, 66, 0.5);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form-table .form-table-image-zoom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: fontello;
  content: '\e80d';
  display: block;
  font-size: 15px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 4px;
}

@media (min-width: 1025px) {
  .form-table .form-table-image-zoom:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media (min-width: 768px) {
  .form-table .form-table-group {
    margin-right: 30px;
  }
}

@media (max-width: 767.98px) {
  .form-table {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .form-table .form-table-group {
    margin-bottom: 24px;
    margin-right: 0;
  }
}

@media (max-width: 575.98px) {
  .form-table .button {
    width: 100%;
  }
}

.icon-hour {
  position: absolute;
  top: 40px;
  right: 16px;
  z-index: 1;
}

.icon-hour[disabled] {
  opacity: 0.5;
}

.react-datepicker__input-container .icon-hour {
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 1024.98px) {
  .menu-account__drop__email {
    display: block !important;
  }
}

.foto-prontuario {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
}

.foto-prontuario .foto-prontuario-image {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 5px;
  overflow: hidden;
}

.foto-prontuario .foto-prontuario-image img {
  width: 110px;
  height: 110px;
}

@media (min-width: 1025px) {
  .foto-prontuario .foto-prontuario-image:hover .foto-prontuario-image-tools {
    opacity: 1;
    visibility: visible;
  }
}

.foto-prontuario .foto-prontuario-image-tools {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 110px;
  height: 110px;
  top: 0;
  left: 0;
  background-color: rgba(57, 48, 66, 0.5);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.foto-prontuario .foto-prontuario-image-zoom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: fontello;
  content: '\e80d';
  display: block;
  font-size: 15px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 4px;
}

@media (min-width: 1025px) {
  .foto-prontuario .foto-prontuario-image-zoom:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media (max-width: 767.98px) {
  .foto-prontuario {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 575.98px) {
  .foto-prontuario .button {
    width: 100%;
  }
}

.box-panel__sidebar__item {
  padding: 10px 10px 10px 30px;
}

.box-panel__sidebar__item.--task {
  padding: 10px 10px 10px 10px;
}

.box-panel__sidebar__indicator {
  min-width: 16px;
  min-height: 16px;
  z-index: 0;
}

.box-panel__sidebar__item .evolution-info__data {
  width: 100%;
}

.box-panel__sidebar__items .evolution-info__data strong {
  margin-left: 0;
  font-size: 14px;
  max-width: 285px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  display: block;
}

.box-panel__sidebar__items .evolution-info__name {
  max-width: 330px;
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  display: block;
  max-height: 21px;
}

.box-panel.--task .box-panel__sidebar {
  width: 15%;
}

.box-panel.--task .box-panel__content {
  width: 85%;
}

.evolution-info__data strong {
  margin-left: 0;
  display: block;
}

.evolution-info__data i {
  color: #919715;
}

.button.--primary,
.button.--danger,
.button.--danger-light {
  min-width: 160px;
}


.table-items-expanded-100__box-item {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin: 12px 0;
  padding-left: 30px;
  padding-right: 16px;
}

.table-items-expanded-50__box-item {
  max-width: 50%;
  position: relative;
  margin: 12px 0;
  padding-left: 30px;
  padding-right: 16px;
}

.botao-prontuario {
  min-width: 0 !important;
}

.tamanho-tooltip {
  width: 340px;
}

.tamanho-tooltip-diagnostico {
  width: 350px;
}

.table-items__bt.\--remove,
.table-items-expanded__bt.\--remove {
  background-color: rgba(250, 55, 0, 0.05);
  color: rgba(250, 55, 0, 0.5);
}

.table-items__bt.\--remove:hover,
.table-items-expanded__bt.\--remove:hover {
  background-color: rgba(250, 55, 0, 0.1);
  color: #fa3700;
}

.form-input-group-append {
  padding: 0;
}

.form-group .form-input-group > input[disabled] {
  background-color: #cfcfcf;
}

.list-btn-unidades {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 10px;
}

.list-btn-unidades > *:not(.--danger) {
  margin-left: 20px;
}

.list-btn-unidades > *:not(.--danger):not(.--danger-light) {
  float: right;
}


@media (max-width: 1024.98px) {
  .list-btn-unidades .button {
    min-width: 110px;
    margin-bottom: 5px;
  }
}

@media (max-width: 575.98px) {
  .list-btn-unidades {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .list-btn-unidades > *:not(:first-child) {
    float: none;
    margin-top: 20px;
    margin-left: 0;
  }
}

.unity-infos-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 8px;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: rgba(180, 199, 227, 0.23);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
}

.unity-infos-item strong {
  font-weight: 500;
}

.unity-infos-item i {
  margin-right: 2px;
  font-size: 16px;
  color: #b4c7e3;
}

.tooltip__drop {
  z-index: 4;
  position: absolute;
  width: 500px;
  height: 300px;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(-10%);
  top: 10%;
  right: 35%;
  border-radius: 5px;
  border: solid 1px #528ce3;
  background-color: #fff;
  -webkit-box-shadow: 0 12px 18px 0 rgba(180, 199, 227, 0.35);
  box-shadow: 0 12px 18px 0 rgba(180, 199, 227, 0.35);
  padding: 2px 7px 5px 7px;
  overflow-x: hidden;
}

.tooltip__drop__title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  padding-left: 9px;
  padding-bottom: 5px;
  border-bottom: solid 1px rgba(180, 199, 227, 0.35);
}

.tooltip__drop__body {
  margin-top: 7px;
  color: #393042;
}

.tooltip__drop__description {
  height: auto;
  font-size: 14px;
  line-height: 1.83;
  padding-left: 9px;
}

.tooltip__drop__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tooltip__drop__bt-close {
  float: right;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  color: #528ce3;
  background-color: rgba(82, 140, 227, 0.1);
  margin-left: 30px;
}

.tooltip__drop__bt-close .icon {
  font-size: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tooltip__drop__bt-close .icon-task {
  font-size: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 575.98px) {
  .tooltip__drop {
    z-index: 3;
    position: absolute;
    width: 300px;
    height: 200px;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(-10%);
    top: 100%;
    right: -100%;
    border-radius: 5px;
    border: solid 1px #528ce3;
    background-color: #fff;
    -webkit-box-shadow: 0 12px 18px 0 rgba(180, 199, 227, 0.35);
    box-shadow: 0 12px 18px 0 rgba(180, 199, 227, 0.35);
    padding: 2px 7px 5px 7px;
    overflow-x: hidden;
  }
}

@media (max-width: 1024.98px) {
  .tooltip__drop {
    z-index: 3;
    position: absolute;
    width: 300px;
    height: 250px;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(-10%);
    top: 50%;
    right: -20%;
    border-radius: 5px;
    border: solid 1px #528ce3;
    background-color: #fff;
    -webkit-box-shadow: 0 12px 18px 0 rgba(180, 199, 227, 0.35);
    box-shadow: 0 12px 18px 0 rgba(180, 199, 227, 0.35);
    padding: 2px 7px 5px 7px;
    overflow-x: hidden;
  }
}


.tooltip__antibioticos_drop {
  z-index: 4;
  position: absolute;
  width: 250px;
  height: 250px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 83%;
  left: 80%;
  border-radius: 3px;
  border: solid 1px #528ce3;
  background-color: #fff;
  -webkit-box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
  box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
  padding: 7px;
  overflow-x: hidden;
  margin-top: 6px;
}

.tooltip__antibioticos_drop__arrow {
  position: absolute;
  width: 16px;
  height: 6px;
  left: 50%;
  top: -6px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip__antibioticos_drop__arrow:before,
.tooltip__antibioticos_drop__arrow:after {
  content: '';
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.tooltip__antibioticos_drop__arrow:before {
  top: 0;
  border-width: 0 6px 6px 6px;
  border-bottom-color: #44c2ff;
}

.tooltip__antibioticos_drop__arrow:after {
  top: 1px;
  border-width: 0 6px 6px 6px;
  border-bottom-color: #fff;
}

.tooltip__antibioticos_drop__bt-close {
  float: right;
  width: 24px;
  height: 24px;
  border-radius: 50px;
  color: #528ce3;
  background-color: rgba(82, 140, 227, 0.1);
  margin-left: 30px;
}

.tooltip__antibioticos_drop__bt-close .icon {
  font-size: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tooltip__antibioticos_drop__body {
  margin-top: 7px;
  color: #393042;
}

.tooltip__antibioticos_drop__title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  border-bottom: solid 1px rgba(180, 199, 227, 0.35);
  padding-left: 9px;
  padding-bottom: 5px;
}

.tooltip__antibioticos_drop__description {
  height: auto;
  font-size: 14px;
  line-height: 1.83;
  padding-left: 9px;
}

@media (max-width: 575.98px) {
  .tooltip__antibioticos_drop {
    z-index: 3;
    position: absolute;
    width: 300px;
    height: 200px;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(-10%);
    top: 0%;
    right: -100%;
    border-radius: 3px;
    border: solid 1px #528ce3;
    background-color: #fff;
    -webkit-box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
    box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
    padding: 2px 7px 5px 7px;
    overflow-x: hidden;
  }
}

@media (max-width: 1024.98px) {
  .tooltip__antibioticos_drop {
    z-index: 3;
    position: absolute;
    width: 250px;
    height: 250px;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(-10%);
    top: 95%;
    left: 10%;
    border-radius: 3px;
    border: solid 1px #528ce3;
    background-color: #fff;
    -webkit-box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
    box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
    padding: 2px 7px 5px 7px;
    overflow-x: hidden;
  }
}

.tooltip-motivos {
  top: 85%;
  left: 65%;
  width: 200px;
  height: 150px;
}

@media (max-width: 1024.98px) {
  .tooltip-motivos {
    top: 80%;
    left: 5%;
    width: 250px;
    height: 150px;
  }
}

@media (max-width: 575.98px) {
  .tooltip-motivos {
    top: 78%;
    left: 10%;
    width: 250px;
    height: 150px;
  }
}

.tooltip-sumario {
  top: 80%;
  left: 21%;
  width: auto;
  height: auto;
  max-height: 300px;
}

.tooltip-sumario .tooltip__antibioticos_drop__description {
  padding: 0;
}

.tooltip-sumario .tooltip__antibioticos_drop__body {
  margin: 0;
}

@media (max-width: 1024.98px) {
  .tooltip-sumario {
    top: 80%;
    left: 5%;
    width: auto;
    height: auto;
  }
}

@media (max-width: 575.98px) {
  .tooltip-sumario {
    top: 80%;
    left: 10%;
    width: 122px;
    height: 122px;
  }

  .tooltip-sumario .tooltip__antibioticos_drop__description .form-summary .form-summary-image:not(:first-child) {
    margin-top: 10px;
    margin-left: 0px;
  }
}

.ajusta-indicator {
  justify-content: space-between;
}

.radio input {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 1;
  margin-top: 6px;
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

.radio input + label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  font-size: 14px;
  top: 0;
  line-height: 1;
}

.radio input + label span {
  margin-left: 6px;
  margin-top: 6px;
  padding-top: 3px;
  color: #393042;
}

.radio input + label::before {
  display: block;
  height: 20px;
  width: 20px;
  content: '';
  top: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.radio input + label span {
  margin-left: 6px;
  padding-top: 3px;
  color: #393042;
}

/* Formulário IVCF */
.componente-ivcf {
  grid-row-gap: 0 !important;
}

.componente-ivcf > r-cell:first-child {
  border-top: #000000 solid 1px;
}

.componente-ivcf > r-cell {
  border-bottom: #000000 solid 1px;
  border-right: #000000 solid 1px;
  border-left: #000000 solid 1px;
}

.componente-ivcf .agrupamento,
.componente-ivcf .pergunta,
.componente-ivcf .opcoes-de-resposta {
  padding: 8px;
  font-size: 14px;
}

.componente-ivcf .agrupamento {
  text-align: center;
}

.componente-ivcf .resposta {
  font-style: italic;
}

.componente-ivcf .cabecalho {
  display: grid;
  grid-template-columns: auto 90px;
  background-color: #E1E0E1;
}

.opcoes-de-respostas div:not(:last-child) {
  border-bottom: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .opcoes-de-respostas div {
    border-top: #000000 solid 1px;
  }

  .opcoes-de-respostas div:not(:last-child) {
    border-bottom: none;
  }
}

.opcoes-de-respostas > div {
  padding: 2px;
  font-size: 14px;
}

.componente-ivcf .cabecalho div {
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.componente-ivcf .cabecalho :not(:last-child) {
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-ivcf .cabecalho {
    display: grid;
    grid-template-columns: none;
    background-color: #E1E0E1;
  }

  .componente-ivcf .cabecalho .titulo-pontuacao {
    display: none;
  }

  .componente-ivcf .cabecalho :not(:last-child) {
    border-right: none;
  }
}

.componente-ivcf .idade {
  display: grid;
  grid-template-columns: 250px 3fr 2fr 90px;
}

@media (max-width: 767.98px) {
  .componente-ivcf .idade {
    display: grid;
    grid-template-columns: none;
  }
}

.componente-ivcf .humor,
.componente-ivcf .cognicao,
.componente-ivcf .auto-percepcao-da-saude,
.componente-ivcf .moradia {
  display: grid;
  grid-template-columns: 250px 5fr 90px;
}

@media (max-width: 767.98px) {

  .componente-ivcf .humor,
  .componente-ivcf .cognicao,
  .componente-ivcf .auto-percepcao-da-saude,
  .componente-ivcf .moradia {
    display: grid;
    grid-template-columns: none
  }
}

.componente-ivcf .auto-percepcao-da-saude .respostas {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  font-style: italic;
}

@media (max-width: 1024.98px) {
  .componente-ivcf .auto-percepcao-da-saude .respostas {
    display: grid;
    grid-template-columns: none;
    font-style: italic;
  }
}

.componente-ivcf .auto-percepcao-da-saude.leitura {
  display: grid;
  grid-template-columns: 250px 3fr 2fr 90px;
}

@media (max-width: 767.98px) {
  .componente-ivcf .auto-percepcao-da-saude.leitura {
    display: grid;
    grid-template-columns: none;
  }
}

.componente-ivcf .auto-percepcao-da-saude > div:not(:last-child),
.componente-ivcf .idade > div:not(:last-child) {
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-ivcf .idade > div:not(:first-child) {
    border-right: none;
  }
}

.componente-ivcf .comorbidades-multiplas .pergunta,
.componente-ivcf .comunicacao .visao .tem-problemas-de-visao,
.componente-ivcf .mobilidade .marcha .teve-quedas-recente,
.componente-ivcf .mobilidade .capacidade-aerobica-e-ou-muscular .pergunta,
.componente-ivcf .mobilidade .alcance-preensao-e-pinca .eh-incapaz-de-elevar-os-bracos-acima-do-ombro,
.componente-ivcf .humor .estah-com-desanimo-tristeza-ou-desesperanca,
.componente-ivcf .cognicao .esquecimento-estah-piorando,
.componente-ivcf .avd-basica .deixou-de-tomar-banho-sozinho,
.componente-ivcf .avd-basica .deixou-de-tomar-banho-sozinho,
.componente-ivcf .avd-instrumental .deixou-de-controlar-suas-financas,
.componente-ivcf .auto-percepcao-da-saude .opcoes-de-respostas,
.componente-ivcf .auto-percepcao-da-saude .pergunta {
  background-color: #E1E0E1;
}

.titulo-vertical {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .titulo-vertical {
    display: none;
  }
}

.componente-ivcf .comorbidades-multiplas,
.componente-ivcf .comunicacao,
.componente-ivcf .mobilidade,
.componente-ivcf .atividades-de-vida-diaria {
  display: grid;
  grid-template-columns: 60px 5fr;
}

.componente-ivcf .comorbidades-multiplas > div:not(:last-child),
.componente-ivcf .comunicacao > div:not(:last-child),
.componente-ivcf .mobilidade > div:not(:last-child),
.componente-ivcf .atividades-de-vida-diaria > div:not(:last-child) {
  border-left: #000000 solid 1px;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas,
.componente-ivcf .comunicacao .audicao,
.componente-ivcf .comunicacao .visao,
.componente-ivcf .mobilidade .continencia-esfincteriana,
.componente-ivcf .mobilidade .marcha,
.componente-ivcf .mobilidade .capacidade-aerobica-e-ou-muscular,
.componente-ivcf .mobilidade .alcance-preensao-e-pinca,
.componente-ivcf .atividades-de-vida-diaria .avd-instrumental {
  display: grid;
  grid-template-columns: 190px 5fr 90px;
}

@media (max-width: 767.98px) {

  .componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas,
  .componente-ivcf .comunicacao .audicao,
  .componente-ivcf .comunicacao .visao,
  .componente-ivcf .mobilidade .continencia-esfincteriana,
  .componente-ivcf .mobilidade .marcha,
  .componente-ivcf .mobilidade .capacidade-aerobica-e-ou-muscular,
  .componente-ivcf .mobilidade .alcance-preensao-e-pinca,
  .componente-ivcf .atividades-de-vida-diaria .avd-instrumental {
    display: grid;
    grid-template-columns: none;
  }
}

@media (max-width: 767.98px) {

  .componente-ivcf .comorbidades-multiplas,
  .componente-ivcf .comunicacao,
  .componente-ivcf .mobilidade,
  .componente-ivcf .atividades-de-vida-diaria {
    display: grid;
    grid-template-columns: none;
  }
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .agrupamento,
.componente-ivcf .comunicacao .audicao .agrupamento,
.componente-ivcf .comunicacao .visao .agrupamento,
.componente-ivcf .mobilidade .continencia-esfincteriana .agrupamento,
.componente-ivcf .mobilidade .marcha .agrupamento,
.componente-ivcf .mobilidade .capacidade-aerobica-e-ou-muscular .agrupamento,
.componente-ivcf .mobilidade .alcance-preensao-e-pinca .agrupamento,
.componente-ivcf .atividades-de-vida-diaria .avd-basica .agrupamento,
.componente-ivcf .atividades-de-vida-diaria .avd-instrumental .titulo-avd-instrumental {
  text-align: right;
  font-weight: 500;
  font-size: 14px;
}

.componente-ivcf .atividades-de-vida-diaria .avd-instrumental .detalhes {
  margin-top: 8px;
  font-size: 10px;
  font-weight: normal;
  font-style: italic;
  text-align: justify;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas > div:not(:last-child),
.componente-ivcf .comunicacao .audicao > div:not(:last-child),
.componente-ivcf .comunicacao .visao > div:not(:last-child),
.componente-ivcf .mobilidade .continencia-esfincteriana > div:not(:last-child),
.componente-ivcf .mobilidade .marcha > div:not(:last-child),
.componente-ivcf .mobilidade .capacidade-aerobica-e-ou-muscular > div:not(:last-child),
.componente-ivcf .mobilidade .alcance-preensao-e-pinca > div:not(:last-child),
.componente-ivcf .humor > div:not(:last-child),
.componente-ivcf .cognicao > div:not(:last-child),
.componente-ivcf .atividades-de-vida-diaria .avd-basica > div:not(:last-child),
.componente-ivcf .atividades-de-vida-diaria .avd-instrumental > div:not(:last-child),
.componente-ivcf .moradia > div:not(:last-child) {
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {

  .componente-ivcf .auto-percepcao-da-saude > div:not(:last-child),
  .componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas > div:not(:last-child),
  .componente-ivcf .comunicacao .audicao > div:not(:last-child),
  .componente-ivcf .comunicacao .visao > div:not(:last-child),
  .componente-ivcf .mobilidade .continencia-esfincteriana > div:not(:last-child),
  .componente-ivcf .mobilidade .marcha > div:not(:last-child),
  .componente-ivcf .mobilidade .capacidade-aerobica-e-ou-muscular > div:not(:last-child),
  .componente-ivcf .mobilidade .alcance-preensao-e-pinca > div:not(:last-child),
  .componente-ivcf .humor > div:not(:last-child),
  .componente-ivcf .cognicao > div:not(:last-child),
  .componente-ivcf .atividades-de-vida-diaria .avd-basica > div:not(:last-child),
  .componente-ivcf .atividades-de-vida-diaria .avd-instrumental > div:not(:last-child),
  .componente-ivcf .moradia > div:not(:last-child) {
    border-right: none;
  }
}

.componente-ivcf .atividades-de-vida-diaria .avd-basica {
  display: grid;
  grid-template-columns: 190px 5fr 90px;
}

@media (max-width: 767.98px) {
  .componente-ivcf .atividades-de-vida-diaria .avd-basica {
    display: grid;
    grid-template-columns: none;
  }
}

.componente-ivcf .mobilidade .itens > div:not(:last-child),
.componente-ivcf .atividades-de-vida-diaria .itens > div:not(:last-child) {
  border-bottom: #000000 solid 1px;
}

.componente-ivcf .comorbidades-multiplas .respostas,
.componente-ivcf .comunicacao .respostas,
.componente-ivcf .mobilidade .respostas,
.componente-ivcf .cognicao .respostas,
.componente-ivcf .humor .respostas,
.componente-ivcf .deixou-de-tomar-banho-sozinho .respostas,
.componente-ivcf .deixou-de-realizar-pequenos-trabalhos-domesticos .respostas,
.componente-ivcf .deixou-de-controlar-suas-financas .respostas,
.componente-ivcf .deixou-de-fazer-compras .respostas,
.componente-ivcf .moradia .respostas {
  display: grid;
  grid-template-columns: 1fr 7fr;
  font-style: italic;
  border-bottom-width: 3px !important;
}

@media (max-width: 1024.98px) {

  .componente-ivcf .comorbidades-multiplas .respostas,
  .componente-ivcf .comunicacao .respostas,
  .componente-ivcf .mobilidade .respostas,
  .componente-ivcf .cognicao .respostas,
  .componente-ivcf .humor .respostas,
  .componente-ivcf .deixou-de-tomar-banho-sozinho .respostas,
  .componente-ivcf .deixou-de-realizar-pequenos-trabalhos-domesticos .respostas,
  .componente-ivcf .deixou-de-controlar-suas-financas .respostas,
  .componente-ivcf .deixou-de-fazer-compras .respostas,
  .componente-ivcf .moradia .respostas {
    display: grid;
    grid-template-columns: none;
    font-style: italic;
    border-bottom-width: 3px !important;
    padding-top: 5px;
  }
}

.componente-ivcf .comunicacao .pergunta,
.componente-ivcf .mobilidade .pergunta,
.componente-ivcf .humor .pergunta,
.componente-ivcf .cognicao .pergunta,
.componente-ivcf .avd-basica .pergunta,
.componente-ivcf .avd-instrumental .pergunta,
.componente-ivcf .moradia .pergunta {
  padding: 0;
}

.componente-ivcf .comunicacao .pergunta > div,
.componente-ivcf .mobilidade .pergunta > div:not(.condicoes),
.componente-ivcf .humor .pergunta > div,
.componente-ivcf .cognicao .pergunta > div,
.componente-ivcf .avd-basica .pergunta > div,
.componente-ivcf .avd-instrumental .pergunta > div,
.componente-ivcf .moradia .pergunta > div {
  padding: 8px;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .agrupamento > div:not(:last-child),
.componente-ivcf .comunicacao .visao,
.componente-ivcf .mobilidade .continencia-esfincteriana .pergunta > div:not(:last-child),
.componente-ivcf .mobilidade .marcha .pergunta > div:not(:last-child),
.componente-ivcf .mobilidade .capacidade-aerobica-e-ou-muscular.edicao .pergunta > div:not(:last-child),
.componente-ivcf .mobilidade .alcance-preensao-e-pinca .pergunta > div:not(:last-child),
.componente-ivcf .humor .pergunta > div:not(:last-child),
.componente-ivcf .cognicao .pergunta > div:not(:last-child),
.componente-ivcf .avd-instrumental .pergunta > div:not(:last-child),
.componente-ivcf .moradia .pergunta > div:not(:last-child) {
  border-bottom: #000000 solid 1px;
}

.capacidade-aerobica-e-ou-muscular.edicao .pergunta .condicoes > div {
  padding: 8px;
  padding-left: 16px;
}

.capacidade-aerobica-e-ou-muscular.leitura .pergunta .condicoes > div {
  padding: 4px;
  padding-left: 16px;
}

.capacidade-aerobica-e-ou-muscular.edicao .pergunta .condicoes > div:first-child {
  padding-bottom: 20px;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .dados-para-calculo-imc .form-group .form-label {
  color: #000000;
  font-size: 14px;
  margin-top: 5px;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .dados-para-calculo-imc .form-group .form-input-group {
  height: 40px !important;
  width: 162px !important;
  background-color: #fff;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .dados-para-calculo-imc .form-group.has-error input {
  height: 40px !important;
  border-radius: 5px 0 0 5px;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .dados-para-calculo-imc .form-group .form-input-group .form-input-group-append {
  height: 40px !important;
  min-width: 60px;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .visualizacao .form-group .form-input-group {
  height: 40px !important;
  width: 162px !important;
  background-color: #fff;
  margin-top: 10px;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .visualizacao .form-group.has-error input {
  height: 40px !important;
  border-radius: 5px 0 0 5px;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .visualizacao .form-group .form-input-group .form-input-group-append {
  height: 40px !important;
  min-width: 60px;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes > div:not(:first-child) {
  padding-bottom: 0;
}

.capacidade-aerobica-e-ou-muscular.edicao .pergunta .condicoes > div:not(:last-child) {
  border-bottom: #000000 dashed 1px;

}

.capacidade-aerobica-e-ou-muscular.edicao .texto-da-pergunta {
  border-bottom: #000000 dashed 1px !important;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .teve-perca-de-peso-nao-intencional strong {
  font-weight: normal;
}

.capacidade-aerobica-e-ou-muscular .pergunta .condicoes .teve-perca-de-peso-nao-intencional span.negrito {
  font-weight: bold;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .agrupamento {
  padding: 0;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .agrupamento > div {
  padding: 4px;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .pergunta input {
  margin-right: 8px;
  margin-top: 4px;
  cursor: pointer;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .pergunta label {
  cursor: pointer;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .pergunta:not(.leitura) > div {
  margin-top: 4px;
  display: grid;
  grid-template-columns: 14px auto;
  gap: 4px;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .pergunta.leitura {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 2fr;
}

.componente-ivcf .comorbidades-multiplas .alguma-das-tres-condicoes-relacionadas .pergunta.leitura .respostas {
  padding-top: 8px;
}

.pontuacao {
  text-align: center;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 4px;
  display: grid;
  align-items: flex-end;
}

@media (max-width: 767.98px) {
  .pontuacao {
    text-align: center;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4px;
    display: none;
    align-items: flex-end;
  }
}

.dados-para-calculo-imc input {
  width: 50%;
}

.tempo-gasto-para-percorrer-quatro-metros input,
.circunferencia-da-panturrilha input {
  width: 100px;
}

.dados-para-calculo-imc label {
  color: rgba(0, 0, 0, 0.65);
  font-style: italic;
}

.dados-para-calculo-imc {
  display: grid;
  grid-template-columns: 200px 200px 200px;
}

@media (max-width: 1024.98px) {
  .dados-para-calculo-imc {
    display: grid;
    grid-template-columns: none;
  }
}

.dados-para-calculo-imc .resultado-calculo-imc {
  display: grid;
  grid-template-rows: 21px auto;
  padding-top: 35px;
}

@media (max-width: 1024.98px) {
  .dados-para-calculo-imc .resultado-calculo-imc {
    display: block;
    grid-template-rows: none;
    padding-top: 15px;
    padding-bottom: 5px;
  }
}

.resposta-imc {
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}

.dados-para-calculo-imc .resultado-calculo-imc span:last-child {
  font-weight: bold;
  font-size: 14px;
  padding-top: 4px;
}

.detalhes-do-calculo {
  font-size: 10px;
  font-style: italic;
}

.container-resposta-imc {
  height: 21px;
}

div.agrupamento {
  display: grid;
  align-items: center;
}

@media (max-width: 767.98px) {
  div.agrupamento {
    display: none;
    align-items: center;
  }
}

.visualizacao-imc {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
}

.visualizacao-imc span:last-child {
  padding-left: 12px;
  font-style: italic;
  font-size: 12px;
}

.resultado-questionario {
  display: grid;
  grid-template-columns: auto 90px;
  font-size: 14px;
}

.resultado-questionario > div:first-child {
  padding: 8px;
  text-align: right;
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .resultado-questionario {
    display: grid;
    grid-template-columns: auto 90px;
    font-size: 14px;
  }

  .resultado-questionario > div:first-child {
    padding: 8px;
    text-align: right;
    border-right: none;
  }
}

.resultado-questionario > div:last-child {
  padding: 8px;
  text-align: center;
}

.estilo-checkbox {
  width: 500px;
}

@media (max-width: 1024.98px) {
  .estilo-checkbox {
    width: 250px;
  }
}

@media (max-width: 767.98px) {
  .estilo-checkbox {
    width: 500px;
  }
}

.estilo-checkbox > input + label span {
  color: #000000;
}

.modo-leitura {
  margin-bottom: 10px !important;
}

.form-input-group {
  height: 46px;
  background-color: #fff;
  border: 1px solid #d3d8dc;
}

.form-group.input-formulario.has-error input {
  height: 45px;
  border-radius: 5px 0 0 5px;
}

.form-group.input-formulario.has-error.form-input-group-append {
  height: 45px;
}

.icon-calendar {
  z-index: 1;
}

.react-datepicker__input-container input {
  background-color: #fff;
  border: 1px solid #d3d8dc;
}

.form-choice-alerta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  border: 1px solid #ffe58f;
  border-radius: 5px;
  background-color: #fffbe6;
  margin-bottom: 5px;
}

.form-choice-alerta + .form-choice-alerta {
  margin-top: 8px;
}

.form-choice-alerta__item {
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.69;
  color: #393042;
}

.form-choice-alerta__item:nth-child(1) {
  width: 5%;
  color: #f1c40f
}

.form-choice-alerta__item:nth-child(2) {
  width: 95%;
  font-size: 14px;
}

.form-choice-alerta__item i {
  font-size: 18px;
}

.form-choice-alerta__item > p {
  margin-top: 10px;
}

.form-choice-alerta__item.--full:nth-child(1) {
  width: 100%;
}

.form-choice-erro {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  border: 1px solid #fa360034;
  border-radius: 5px;
  background-color: rgba(250, 55, 0, 0.1);
  margin-bottom: 5px;
}

.form-choice-erro + .form-choice-erro {
  margin-top: 8px;
}

.form-choice-erro__item {
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.69;
  color: #393042;
}

.form-choice-erro__item:nth-child(1) {
  width: 5%;
  color: #fa3700
}

.form-choice-erro__item:nth-child(2) {
  width: 95%;
  font-size: 14px;
}

.form-choice-erro__item i {
  font-size: 18px;
}

.form-choice-erro__item > p {
  margin-top: 10px;
}

.form-choice-erro__item.--full:nth-child(1) {
  width: 100%;
}

.botao-historico {
  visibility: visible;
  opacity: 1;
}

.botao-historico i {
  color: #528ce3;
}

.botao-historico:disabled, .botao-historico[disabled], .botao-historico.is-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.user-paciente-agenda-modal .skeleton-name {
  margin-bottom: 10px;
  margin-left: 10px;
  border-radius: 5px;
}

.user-paciente-agenda-modal .skeleton-paciente-info {
  margin-bottom: 5px;
  margin-left: 10px;
  border-radius: 5px;
}

.skeleton-info {
  margin-bottom: 8px;
  border-radius: 5px;
}

.skeleton-observacao {
  border-radius: 5px;
}

.form-group.text-align {
  text-align: left;
}

.estilo-block {
  font-size: 18px !important;
}

.box-hours-free__item .passo-3 {
  padding-top: 0;
}

.renderizar-horarios .ajusta-largura {
  width: 90px;
}

.renderizar-horarios .ajusta-largura .with-thumb-sidebar {
  padding: 5px;
  margin-right: 0;
  width: 90px;
}

.renderizar-horarios .ajusta-largura .with-thumb-sidebar img {
  border-radius: 5px;
  height: 80px;;
  width: 80px;
}

.box-hours-free__item.list-buttom-hours.padding {
  padding: 3px;
}

.horario {
  margin-right: 4.3px;
  margin-bottom: 4.3px;
}

.form-item-passo-3 .image-paciente .with-thumb-sidebar {
  height: 115px;
  width: 115px;
}

.form-choice-recorrentes {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 50px;
  border-radius: 5px;
  background-color: #EDF2F8;
}

.form-choice-recorrentes + .form-choice-recorrentes {
  margin-top: 8px;
}

.form-choice-recorrentes__item {
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.69;
  color: #393042;
}

.form-choice-recorrentes__item:nth-child(1) {
  width: 65%;
}

.form-choice-recorrentes__item:nth-child(2) {
  width: 35%;
}

.form-choice-recorrentes__item > p {
  margin-top: 10px;
}

.form-choice-recorrentes__item > i {
  font-size: 16px;
  margin-right: 2px;
}

.form-choice-recorrentes__item i strong {
  font-weight: 500;
}

.form-choice-recorrentes__bt-close {
  position: absolute;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  font-size: 8px;
  padding: 0;
  right: 15px;
  border-radius: 50%;
  background-color: #528ce3;
  color: #fff;
}

.form-choice-recorrentes__bt-close > i {
  color: #fff !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.unity-infos.agendamentos {
  padding: 5px 5px 5px 0;
}

.unity-infos__item.agendamentos {
  padding: 10px 10px;
}

.unity-infos__item.agendamentos button {
  float: right;
  cursor: pointer;
  padding: 0;
}

.unity-infos.agendamentos i {
  color: #b4c7e3;
}

.unity-infos.agendamentos .ml-10 {
  margin-left: 10px;
}

.form-confirmar-agendamento {
  background-color: #EDF2F8;
  border-radius: 8px;
}

.form-item__choice-passo-3.pd-ajuste {
  padding-left: 22px;
}

.form-item__choice-passo-3.pd-ajuste.top {
  padding-top: 11px;
}

.form-item__choice-passo-3.pd-ajuste.bottom {
  padding-bottom: 11px;
}

.estilo-do-modal-foto {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 2px;
  width: 30%;
}

.modal-foto > i {
  position: absolute;
  cursor: pointer;
  font-size: 15px;
  right: 0;
  color: rgba(0, 0, 0, 0.45);
}

.modal-foto > img {
  width: 100%;
  border: 15px solid #fff;
}

.estilo-do-modal-imagem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  max-width: 95vw;
  /* Define a largura máxima para 80% da largura da tela */
  max-height: 95vh;
  /* Define a altura máxima para 80% da altura da tela */
  overflow: auto;
  /* Adiciona uma barra de rolagem quando o conteúdo exceder o tamanho do modal */
}

.modal-imagem > img {
  max-width: 100%;
  /* Define a largura máxima da imagem para 100% */
  height: auto;
  /* Isso mantém a proporção original da imagem */
  border: 5px solid #fff;
}


.form-prescricao-marker:nth-child(1) {
  left: 33%;
}

.form-prescricao-marker:nth-child(2) {
  left: 68%;
}

.form-prescricao textarea {
  font-family: 'Courier New', Courier, monospace;
  columns: 48;
}

.font-react-menstion {
  font-family: 'Courier New', Courier, monospace;
}

.form-title.importacao {
  color: #528ce3;
}

.form-title.importacao > a {
  font-weight: bold;
}

.importacao input {
  display: none;
}

.m0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mtp-5 {
  margin-top: 5px;
}

.mt-5n {
  margin-top: -0.5rem !important;
}

.mt-35 {
  margin-top: 3.5rem !important;
}

.mt-24 {
  margin-top: 24px;
}

.mt-36 {
  margin-top: 36px;
}

.mleft-5 {
  margin-left: 5px !important;
}

.form-summary-image > img {
  height: 112px;
  width: 112px;
}

.botao-ivcf {
  min-width: 130px;
  margin-top: 10px;
}

.w-90 {
  width: 90% !important;
}

.h-auto {
  height: auto;
}

.informacoes-modal {
  padding: 0 0 20px 0;
  margin-top: 0px;
  border-radius: 4px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.informacoes-modal__name {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #393042;
}

.informacoes-modal__info p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
}

@media (max-width: 767.98px) {
  .informacoes-modal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .informacoes-modal__info p {
    margin-bottom: 10px;
  }
}

.user-paciente-agenda__info {
  gap: 20px;
}

.user-paciente-agenda {
  padding: 15px;
}

.modal-vacinas,
.modal-adicionar-template,
.modal-cancelar-atendimento,
.modal-selecionar-tipo-de-atendimento,
.modal-selecionar-tipo-de-tarefa {
  position: absolute;
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 2px;
  max-height: 90%;
  overflow-y: auto;
  max-height: 95%;
}

@media (min-width: 1025px) {
  .modal-selecionar-tipo-de-atendimento {
    max-width: 50%;
  }
}

@media (max-width: 1024.98px) {

  .modal-vacinas,
  .modal-adicionar-template,
  .modal-cancelar-atendimento,
  .modal-selecionar-tipo-de-atendimento,
  .modal-selecionar-tipo-de-tarefa {
    width: 75%;
    max-height: 90%;
    overflow-y: scroll;
  }
}

@media (max-width: 767.98px) {

  .modal-vacinas,
  .modal-adicionar-template,
  .modal-cancelar-atendimento,
  .modal-selecionar-tipo-de-atendimento,
  .modal-selecionar-tipo-de-tarefa {
    width: 100%;
    max-height: 100%;
    overflow-x: scroll;
    margin: 20px 0 20px;
  }
}

.form .form-title,
.title-adicionar {
  margin-bottom: 10px;
}

.react-confirm-alert .react-confirm-alert-button-group > button.is-danger {
  border-color: #e74c3c;
  background-color: #e74c3c;
  color: #fff;
}

.table-items-expanded__bt.\--remove {
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 1025px) {
  .table-items-expanded__collapsable:hover .table-items-expanded__bt.\--remove {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 1024.98px) {
  .table-items-expanded__collapsable.is-active .table-items-expanded__bt.\--remove {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 1024.98px) {
  .table-items-expanded__bt.\--edit-fixed {
    opacity: 0;
    visibility: hidden;
  }

  .table-items-expanded__collapsable.is-active .table-items-expanded__bt.\--edit-fixed {
    opacity: 1;
    visibility: visible;
  }
}

.form-summary .form-summary-group {
  min-width: 185px;
}

.form-summary .form-summary-images {
  display: grid;
  grid-template-columns: repeat(3, 112px);
  grid-gap: 15px;
}

@media (max-width: 1024.98px) {
  .form-summary .form-summary-images {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .form-summary .form-summary-images {
    grid-template-columns: repeat(3, 1fr);
  }
}

.form-summary .form-summary-files {
  width: 100%;
}

.form-summary .form-summary-image {
  position: relative;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .form-summary .form-summary-group {
    margin-right: 15px;
  }
}

.react-confirm-alert .react-confirm-alert-button-group > button.button.\--primary {
  border-color: #528ce3;
  background-color: #528ce3;
  color: #fff;
}

.resumo-do-atendimento .nome-secao {
  font-size: 22px;
  color: #528ce3;
  margin-top: 0px !important;
}

.resumo-do-atendimento .table-items__row.is-head {
  min-height: 0px;
}

.resumo-do-atendimento .table-items__head {
  line-height: normal;
  padding-top: 0px;
}

.resumo-do-atendimento .table-items__row {
  margin-top: 8px;
}

.resumo-do-atendimento r-grid {
  gap: 8px
}

.resumo-do-atendimento .form-title {
  margin-bottom: 0;
  margin-top: 8px;
}

.resumo-do-atendimento .form-title.nome-secao {
  font-size: 20px;
  margin-bottom: 5px;
}

.prontuario .form-title.nivel-1,
.resumo-do-atendimento .form-title.nivel-1 {
  font-size: 18px;
}

.prontuario .form-title.nivel-2,
.resumo-do-atendimento .form-title.nivel-2 {
  font-size: 16px;
}

.resumo-do-atendimento .table-items__row {
  min-height: 38px;
}

.resumo-do-atendimento .table-items.--secondary .table-items__data {
  padding: 12px;
}

.botao-finalizar-atendimento {
  cursor: pointer;
}

.cabecalho-fixo-prontuario {
  z-index: 4;
}

.corpo-fixo-prontuario {
  z-index: 0;
}

.cabecalho-prontuario .tag__info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}

@media (max-width: 767.98px) {
  .resumo-do-atendimento .table-items.--secondary .table-items__tools {
    top: 6px;
    right: 8px;
  }
}

.header-page-form {
  margin-top: 0;
  padding-top: 8px;
  background-color: #f1f3f4;
}

.cabecalho-fixo-prontuario .header-page-form {
  padding-bottom: 8px;
}


.user-paciente-agenda {
  z-index: 2;
  position: relative;
  margin-top: 0;
}

/* .cabecalho-prontuario, */
.cabecalho-do-atendimento {
  box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
  border: 1px solid rgba(180, 199, 227, 0.23);
}

.cabecalho-prontuario .nome_paciente_prontuario {
  font-size: 16px;
  margin-bottom: 6px;
}

.cabecalho-prontuario .nome_paciente_prontuario:hover {
  color: #528ce3;
}

.escolher-unidade {
  padding: 40px 30px;
}

.file-upload-item__bt.\--remove {
  background-color: rgba(250, 55, 0, 0.1);
  color: #fa3700;
}

.dica-de-pesquisa {
  font-size: 12px;
  line-height: 1.83;
  color: #393042;
  opacity: 0.5;
}

.dica-de-pesquisa > i {
  color: #0004fa;
}

@media (min-width: 1025px) {
  .dica-de-pesquisa {
    position: absolute;
  }
}

.encaminhamentos.week-options {
  height: 48px;
  background-color: #fff;
}

.encaminhamentos.week-options__item {
  height: 48px;
  text-align: center;
}

@media (min-width: 1025px) {
  .modal-horario-agenda {
    width: 950px;
  }

  .modal-internacao {
    width: 800px;
  }

  .modal-itu {
    width: 950px;
  }

  .table-items-expanded-45 {
    width: 45%;
    max-width: 45%;
    position: relative;
    margin: 12px 0;
    padding-left: 30px;
    padding-right: 16px;
  }

  .table-items-expanded-40 {
    width: 33.5%;
    max-width: 33.5%;
    position: relative;
    margin: 12px 0;
    padding-left: 30px;
    padding-right: 16px;
  }

  .table-items-expanded-20 {
    width: 20%;
    max-width: 20%;
    position: relative;
    margin: 12px 0;
    padding-left: 30px;
    padding-right: 16px;
  }

}

.table-items__mobile-value-tarefas {
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.unity-infos__item-tarefas {
  border: 1;
  margin-bottom: 8px;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: rgba(180, 199, 227, 0.23);
  font-size: 14px;
  font-weight: 300;
  line-height: 2.57;
  color: #393042;
}

.unity-infos__item-tarefas strong {
  font-weight: 500;
}

.table-items-expanded__box-item.icon {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 16px;
  color: #b4c7e3;
}

pre {
  font-family: Roboto;
  white-space: pre-wrap;
}

.avatar-upload__thumb {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 112px;
  height: 112px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
}

.form-summary .form-summary-images.input-foto {
  display: grid;
  grid-template-columns: repeat(1, 112px);
  grid-gap: 15px;
}

@media (max-width: 1024.98px) {
  .form-summary .form-summary-images.input-foto {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 767.98px) {
  .form-summary .form-summary-images.input-foto {
    grid-template-columns: repeat(1, 1fr);
  }
}

.prontuario .form-title {
  margin-bottom: 0;
}

.prontuario .table-items-expanded__row,
.prontuario .table-items-expanded__collapsable-cols,
.prontuario .table-items-expanded__collapsable-content {
  margin-top: 8px;
}

.prontuario .table-items-expanded__row:first,
.prontuario .table-items-expanded__collapsable-cols:first,
.prontuario .table-items-expanded__collapsable-content:first {
  margin-top: 8px;
  background-color: #0004fa;
}

.razao {
  font-size: 14px;
  font-family: Roboto;
}

.razao.has-error {
  color: #e74c3c;
}

.grafico-proteina-creatinina {
  height: 200px;
}

.row-gap-0 {
  grid-row-gap: 0 !important;
}

.mt-8 {
  margin-top: 8px;
}

.button-add-antibioticos {
  width: 44px !important;
  height: 44px !important;
  margin-top: 27px;
  margin-left: 12px;
}

.button-add-antibioticos .icon {
  font-size: 17px;
}

.grid-antibioticos {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

@media (max-width: 1024.98px) {
  .grid-antibioticos {
    display: grid;
    grid-template-columns: 11fr 1fr;
    margin-top: 20px;
  }
}

@media (max-width: 767.98px) {
  .grid-antibioticos {
    display: grid;
    grid-template-columns: 7fr 1fr;
    margin-top: 20px;
  }
}

.box-agenda__event__time {
  font-size: 14px;
  font-weight: normal !important;
  color: #ffffff;
}

.box-agenda__event__local {
  margin-top: 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.box-agenda__block-event__time {
  font-size: 12px;
  font-weight: normal;
  color: #393042;
}

.box-agenda__block-event__local {
  font-size: 12px;
  color: rgba(57, 48, 66, 0.8);
  margin-top: 5px;
}

.componente-zarit {
  grid-row-gap: 0 !important;
}

.componente-zarit > r-cell:first-child {
  border-top: #000000 solid 1px;
}

.componente-zarit > r-cell {
  border-bottom: #000000 solid 1px;
  border-right: #000000 solid 1px;
  border-left: #000000 solid 1px;
}

.componente-zarit .agrupamento {
  display: grid;
  grid-template-columns: auto 100px;
}

.componente-zarit .pergunta,
.componente-zarit .opcoes-de-resposta {
  padding: 8px;
  font-size: 14px;
}

.componente-zarit .pergunta {
  text-align: left;
}

.componente-zarit .cabecalho {
  display: grid;
  grid-template-columns: auto 100px;
  background-color: #E1E0E1;
}

.componente-zarit .cabecalho div {
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.componente-zarit .cabecalho :not(:last-child) {
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-zarit .cabecalho {
    display: grid;
    grid-template-columns: none;
    background-color: #E1E0E1;
  }

  .componente-zarit .cabecalho .titulo-pontuacao {
    display: none;
  }

  .componente-zarit .cabecalho :not(:last-child) {
    border-right: none;
  }

  .componente-zarit .agrupamento {
    display: grid;
    grid-template-columns: none;
  }
}

.componente-zarit .agrupamento > div:not(:last-child) {
  border-right: #000000 solid 1px;
}

.componente-zarit .agrupamento:nth-child(2n+1) {
  background-color: #E1E0E1;
}

.componente-zarit .resultado-questionario {
  display: grid;
  grid-template-columns: auto 100px;
  font-size: 14px;
}

.componente-zarit .resultado-questionario > div:first-child {
  padding: 8px;
  text-align: left;
  border-right: #000000 solid 1px;
}

.componente-zarit .resultado-questionario .legenda {
  font-size: 12px;
  font-style: italic;
  margin-top: 5px;
}

@media (max-width: 767.98px) {
  .componente-zarit .resultado-questionario {
    display: grid;
    grid-template-columns: auto 100px;
    font-size: 14px;
  }

  .componente-zarit .resultado-questionario > div:first-child {
    padding: 8px;
    text-align: right;
    border-right: none;
  }

  .componente-zarit .agrupamento > div:not(:last-child) {
    border-right: none;
  }
}

.componente-zarit .pergunta .respostas-leitura,
.componente-zarit.paciente-leitura {
  font-size: 14px;
}

.docs-info {
  margin-top: 10px !important;
  padding: 0 !important;
}

.box-panel__content__actions {
  margin-bottom: 20px !important;
}

.box-panel__content__actions .button {
  min-width: auto !important;
  width: auto !important;
  max-width: none
}

.resumo-informacoes {
  font-size: 12px;
}

.box-panel__sidebar__item + .box-panel__sidebar__item {
  border-bottom: 1px solid #e8e8e8;
}

.menu-account__attendance-drop__wrapper {
  height: auto;
}

.menu-account__attendance-drop__item:hover {
  background-color: rgba(82, 140, 227, 0.1);
}

.assistence-end.is-disabled:not(.assistence-end__time) {
  background-color: #8f8599;
  pointer-events: none;
}

.componente-casp-16 {
  grid-row-gap: 0 !important;
}

.componente-casp-16 > r-cell:first-child {
  border-top: #000000 solid 1px;
}

.componente-casp-16 > r-cell {
  border-bottom: #000000 solid 1px;
  border-right: #000000 solid 1px;
  border-left: #000000 solid 1px;
}

.componente-casp-16 .componente {
  display: grid;
  grid-template-columns: 250px 5fr 100px;
}

.componente-casp-16 .agrupamento,
.componente-casp-16 .pergunta,
.componente-casp-16 .pergunta-2n {
  padding: 8px;
  font-size: 14px;
}

.componente-casp-16 .agrupamento {
  text-align: center;
}

.componente-casp-16 .pergunta,
.componente-casp-16 .pergunta-2n {
  text-align: left;
}

.componente-casp-16 .resposta {
  font-style: italic;
}

.componente-casp-16 .cabecalho {
  display: grid;
  grid-template-columns: auto 100px;
  background-color: #E1E0E1;
}

.componente-casp-16 .cabecalho div {
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.componente-casp-16 .cabecalho :not(:last-child) {
  border-right: #000000 solid 1px;
}

.componente-casp-16 .componente .perguntas > div:not(:last-child) {
  border-bottom: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-casp-16 .cabecalho {
    display: grid;
    grid-template-columns: none;
    background-color: #E1E0E1;
  }

  .componente-casp-16 .cabecalho .titulo-pontuacao {
    display: none;
  }

  .componente-casp-16 .cabecalho :not(:last-child) {
    border-right: none;
  }

  .componente-casp-16 .componente {
    display: grid;
    grid-template-columns: none;
  }

  .componente-casp-16 .componente > div:not(:last-child) {
    border-right: none !important;
  }
}

.componente-casp-16 .componente .respostas.leitura {
  display: grid;
  font-style: italic;
}

@media (max-width: 1024.98px) {
  .componente-casp-16 .componente .respostas {
    display: grid;
    grid-template-columns: none;
    font-style: italic;
  }
}

.componente-casp-16 .componente .pergunta:nth-child(2n+1) {
  background-color: #E1E0E1;
}

.componente-casp-16 .componente .pergunta-2n:nth-child(2n) {
  background-color: #E1E0E1;
}

.componente-casp-16 .componente > div:not(:last-child),
.componente-casp-16 .perguntas {
  border-right: #000000 solid 1px;
}

.componente-casp-16 .resultado-questionario {
  display: grid;
  grid-template-columns: auto 100px;
  font-size: 14px;
  background-color: #E1E0E1;
}

.componente-casp-16 .resultado-questionario > div:first-child {
  padding: 8px;
  text-align: center;
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-casp-16 .resultado-questionario {
    display: grid;
    grid-template-columns: 250px 5fr;
    font-size: 14px;
  }

  .componente-casp-16 .resultado-questionario > div:first-child {
    padding: 8px;
    text-align: center;
    border-right: none;
    border-right: #000000 solid 1px;
  }
}

.componente-casp-16 .pergunta .respostas-leitura,
.componente-casp-16 .pergunta-2n .respostas-leitura,
.componente-casp-16.paciente-leitura {
  font-size: 14px;
  font-style: italic;
}

.tags-do-paciente-no-prontuario {
  margin-bottom: 8px;
  margin-top: 8px;
}

/* Example Styles for React Tags*/

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
.tag {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}

.tag .tag-input,
.tag .tag-input:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #d3d8dc;
  border-radius: 5px;
  padding: 0 4px;
}

/* Estilo desabilitado*/
.tag .tag-input.is-disabled {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #d3d8dc;
  border-radius: 5px;
  background-color: #cfcfcf;
  pointer-events: none;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin-bottom: 4px;
  margin-top: 4px;
  margin-right: 8px;
  cursor: move;
  border-radius: 2px;
  color: #0052CC;
  border-radius: 5px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  z-index: 1000;
  font-size: 12px;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
  background: white;
  width: 200px;
  padding-left: 0;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

@media (min-width: 1024.98px) {
  .cabecalho-planos-de-cuidado {
    width: 321.55px;
    text-overflow: ellipsis;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    direction: ltr;
    display: block;
    max-height: 21px;
  }
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 100px;
}

.ReactModal__Body--open .main-footer {
  position: fixed;
}

.main-footer {
  width: 100%;
  height: 70px;
  background: #fff;
  position: absolute;
  bottom: 0px;
  left: 0;
}

.main-footer .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.main-footer p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #92959d;
  margin: 0;
  text-align: center;
}

.cabecalho-do-atendimento,
.cabecalho-prontuario,
.prontuario fieldset {
  padding: 10px !important;
}

.informacoes-do-paciente-no-cabecalho-do-atendimento {
  padding-left: 5px;
  font-size: 14px;
}

.gestao-de-condicoes-clinicas .table-items-expanded__row,
.gestao-de-condicoes-clinicas .table-items-expanded__collapsable,
.gestao-de-condicoes-clinicas .table-items-expanded__collapsable-cols,
.gestao-de-condicoes-clinicas .table-items__row {
  min-height: 42px;
  margin-top: 5px;
}

@media (max-width: 1024.98px) {
  .gestao-de-condicoes-clinicas .table-items__row {
    min-height: 50px;
    margin-top: 5px;
  }

  .gestao-de-condicoes-clinicas .table-items__row.is-active {
    min-height: 100px;
  }
}

.gestao-de-condicoes-clinicas .table-items-expanded__row .table-items__data,
.gestao-de-condicoes-clinicas .table-items__row .table-items__data {
  padding: 10px;
}

.gestao-de-condicoes-clinicas .table-items-expanded__collapsable-cols .table-items-expanded__data {
  padding: 8px;
}

.gestao-de-condicoes-clinicas .table-items-expanded__row .items__tools,
.gestao-de-condicoes-clinicas .table-items-expanded__collapsable .items-expanded__tools,
.gestao-de-condicoes-clinicas .table-items__row .items__tools {
  right: 10px;
}

.gestao-de-condicoes-clinicas .table-items-expanded__tools,
.gestao-de-condicoes-clinicas .table-items__tools {
  right: 10px !important;
  top: 5px;
}

.gestao-de-condicoes-clinicas .table-items-expanded__head,
.gestao-de-condicoes-clinicas .table-items__head {
  padding: 5px 10px 0;
}

.gestao-de-condicoes-clinicas .table-items-expanded__bt {
  width: 32px;
  height: 32px;
}

.gestao-de-condicoes-clinicas .table-items-expanded__data,
.gestao-de-condicoes-clinicas .table-items-expanded__box {
  padding: 5px 10px;
}

.corpo-fixo-prontuario r-grid {
  gap: 20px;
}

.razao.anti-hbs {
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal-finalizar-atendimento {
  width: 1100px;
}

.finalizar-atendimento h2 {
  padding: 0 20px;
}

.finalizar-atendimento p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #393042;
  padding-top: 10px;
}

.resumo-do-atendimento fieldset {
  padding: 10px 20px;
}

.table-items.abas-qgcc,
.table-items-expanded.abas-qgcc {
  min-height: 150px !important;
}

.table-items-expanded.is-loading:before,
.table-items-expanded.is-loading:after {
  z-index: 2;
}

.form.is-loading:before,
.form.is-loading:after {
  z-index: 2;
}

.box-agenda.is-loading:before,
.box-agenda.is-loading:after {
  z-index: 2;
}

.cabecalho-prontuario,
.cabecalho-do-atendimento {
  background-color: #fff;
}

.user-paciente-cabecalho {
  margin-top: 12px;
  border-radius: 4px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.user-paciente-cabecalho__info {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
}

@media (min-width: 1024.98px) {
  .user-paciente-cabecalho__info {
    display: grid;
    grid-template-columns: 30% auto;
    gap: 5px 20px;
  }

  .user-paciente-cabecalho__dados_pessoais {
    grid-template-rows: repeat(3, 21px);
    display: grid;
    gap: 5px 0;
  }

  .user-paciente-cabecalho__dados_medicos {
    display: grid;
    gap: 5px 20px;
    grid-template-rows: repeat(4, minmax(min-content, 21px));
    /* No máximo 4 parágrafos por coluna */
    grid-auto-flow: column;
  }
}

.icon.icon-birthday:hover {
  color: #528ce3;
}

.react-datepicker__input-container input[disabled] {
  opacity: 1;
  color: #999;
  background-color: #cfcfcf;
}

.form-group > input[disabled] {
  color: #999;
}

@media (min-width: 1024.98px) {
  .modal-alerta {
    width: 615px;
    top: 12%;
  }
}

.alerta-prontuario {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
}

.alerta-prontuario .titulo {
  font-size: 16px;
  font-weight: 500;
  color: #393042;
  padding: 5px 20px;
}

.alerta-prontuario .list-btn {
  margin: 20px 0 0 0;
}

.alerta-prontuario .list-btn button {
  min-width: 70px;
  height: 45px;
  padding: 0px 14px;
}

@media (min-width: 767.98px) {
  .alerta-prontuario .list-btn {
    float: right;
  }
}

.sem-atendimento {
  width: 100%;
  height: auto;
  padding: 20px 10px;
}

.sem-atendimento .mensagem {
  padding: 0;
}

.border-bottom-none {
  border-bottom: none;
}

.border-top-active {
  border-top: solid 1px rgba(180, 199, 227, 0.35);
}

.table-items__row.is-disabled,
.table-items-expanded__collapsable.is-disabled {
  pointer-events: visible
}

.table-items__row.is-disabled .table-items__data,
.table-items-expanded__collapsable.is-disabled .table-items__data,
.table-items-expanded__collapsable.is-disabled .table-items-expanded__data {
  opacity: 0.2;
}

.table-items__row.is-disabled .table-items__data:last-child {
  opacity: 1;
}

.page-login .item-username input:focus,
.page-login .item-password input:focus {
  border: solid 1px #528ce3;
  background-color: #fff;
  outline: 0;
}

.page-login .item-username input,
.page-login .item-password input {
  background-color: #fff !important;
}

.page-login .item-username.has-error input,
.page-login .item-password.has-error input {
  border: 1px solid #e74c3c !important;
  background-color: #fdecec !important;
}

.page-login input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.page-login input:-webkit-autofill {
  -webkit-text-fill-color: #528ce3 !important;
}

.mh-4 {
  margin: 4px 0;
}

.grafico {
  height: 200px;
}

.icon-tabela-vazia {
  display: block;
  margin: auto;
  margin-bottom: 20px;
  z-index: 1;
  height: 40px;
  width: 64px;
  opacity: 0.2;
}

.icon-atendimento-privado {
  display: block;
  margin: auto;
  z-index: 1;
  height: 50px;
  width: 40px;
  opacity: 0.6;
}

.table-items__result p {
  opacity: 0.3;
}

.table-items__result span {
  opacity: 0.7;
}

.pills-nav__item.dados-nao-salvos {
  color: #e74c3c;
}

.gestao-de-condicoes-clinicas .table-items__result {
  padding: 30px 0 30px;
}

.verificando-permissoes {
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #b4c7e3;
  margin-bottom: 20px;
}

.container-da-prescricao {
  position: relative;
  margin-bottom: 8px;
}

@media (min-width: 700px) {
  .delimitador-da-prescricao-a5 {
    height: 104%;
    top: -15px;
    left: 415px;
    border-left-style: dotted;
    border-left-width: 1px;
    border-left-color: rgb(169, 169, 169);
    position: absolute;
    /* z-index: 100; */
  }

  .texto-delimitador-a5 {
    top: -15px;
    left: 420px;
    position: absolute;
    /* z-index: 100; */
    font-weight: bolder;
    color: rgb(169, 169, 169);
  }

  .delimitador-da-prescricao-a4 {
    height: 104%;
    top: -15px;
    left: 616px;
    border-left-style: dotted;
    border-left-width: 1px;
    border-left-color: rgb(169, 169, 169);
    position: absolute;
    /* z-index: 100; */
  }

  .texto-delimitador-a4 {
    top: -15px;
    left: 622px;
    position: absolute;
    /* z-index: 100; */
    font-weight: bolder;
    color: rgb(169, 169, 169);
  }
}

@media (max-width: 700px) {
  .texto-delimitador-a5 {
    display: none;
  }

  .texto-delimitador-a4 {
    display: none;
  }
}

.resumo-do-atendimento .form-group {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.user-paciente-agenda-modal__header {
  display: grid;
  grid-template-columns: auto 50px;
  gap: 10px;
}

.user-paciente-agenda-modal__header .botao-agendamento {
  min-width: 0 !important;
  margin-bottom: 5px;
  max-height: 41px;
}

textarea[name=anamnese] {
  resize: vertical;
}

.right-30 {
  right: -30%;
}

.right20 {
  right: 20%;
}

.right-20 {
  right: -20%;
}

.top110 {
  top: 100%
}

.form-summary-image.exames {
  height: 135px;
}

.form-summary-image.exames img {
  background-color: #edf2f8;
  border-radius: 5px;
}

.form-summary-image.exames p {
  text-align: justify;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 5px;
}

.exames.nome {
  text-align: center;
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 5px;
}

.form-summary-image.exames {
  height: 135px;
}

.form-summary-image.exames img {
  background-color: #edf2f8;
  border-radius: 5px;
}

.form-summary .form-summary-image.exames {
  height: 112px;
}

@media (min-width: 1025px) {
  .form-summary .form-summary-image.exames:hover {
    height: 112px;
    opacity: 1;
    visibility: visible;
  }
}

.form-summary .form-summary-images.internacao {
  display: grid;
  grid-template-columns: repeat(4, 112px);
  grid-gap: 15px;
}

@media (max-width: 1023.98px) {
  .form-summary .form-summary-images.internacao {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767.98px) {
  .form-summary .form-summary-images.internacao {
    grid-template-columns: repeat(3, 1fr);
  }
}

.box-agenda.resumo,
.box-agenda.relatorios {
  overflow-x: visible;
}

.centraliza-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, 204px);
  gap: 15px;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .centraliza-dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fit, 168px);
    gap: 15px;
    justify-content: center;
  }
}

.evolution-info.cancelado > p {
  text-decoration: line-through;
}

.informacoes-de-auditoria {
  margin-top: 10px;
  text-align: right;
}

.informacoes-de-auditoria span {
  font-size: 14px;
  font-style: italic;
  color: #999999;
}

.informacoes-de-auditoria .titulo-do-usuario {
  font-weight: 600;
}

@media (max-width: 1024.98px) {
  .box-panel {
    display: block;
  }

  .box-panel__sidebar {
    display: block;
    width: 100%;
  }

  .box-panel__sidebar__items {
    height: 250px;
  }

  .box-panel__content.form {
    border-top: 1px solid #528ce3;
    padding-top: 15px;
  }

  .box-panel__sidebar__actions .form-search {
    max-width: 100%;
  }

  .box-panel__content__actions {
    margin-top: 0 !important;
  }

  .page-configuracoes-agendamento .header-page-tools .button {
    margin-top: 15px;
  }

  .box-panel.--narrow .box-panel__sidebar {
    width: auto;
  }

  .box-panel__sidebar__items .evolution-info__name {
    max-width: 700px;
  }

  .box-panel.--task .box-panel__sidebar {
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .box-panel__content__actions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .box-panel__content__actions * + * {
    margin-top: 10px;
    margin-left: 0;
  }

  .box-panel__sidebar__items .evolution-info__name {
    max-width: 300px;
  }
}

.page-prontuario-atendimento .header-page-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-prontuario-atendimento .header-page-bar .header-page-nav {
  width: 85%;
}

.page-prontuario-atendimento .header-page-bar .header-page-tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 15%;
}

.cabecalho-fixo-prontuario-atendimentos {
  z-index: 3;
}

.tooltip-encaminhamentos {
  max-width: 450px;
}

.dashboard-item p {
  margin: 0 5px;
}

textarea:disabled {
  background-color: #cfcfcf;
}

.avatar-upload__thumb-none {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 112px;
  height: 112px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
}

.avatar-upload__thumb-none:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  background-size: 31px auto;
}

.form-group > input[disabled]::-webkit-input-placeholder {
  color: rgba(153, 153, 153, 0.6);
}

.form-group > input[disabled]::-moz-placeholder {
  color: rgba(153, 153, 153, 0.6);
}

.form-group > input[disabled]:-ms-input-placeholder {
  color: rgba(153, 153, 153, 0.6);
}

.form-group > input[disabled]::-ms-input-placeholder {
  color: rgba(153, 153, 153, 0.6);
}

.form-group > input[disabled]::placeholder {
  color: rgba(153, 153, 153, 0.6);
}

.list-unidades-line:hover {
  -webkit-box-shadow: 0 5px 8px rgba(180, 199, 227);
  box-shadow: 0 5px 8px rgba(180, 199, 227);
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(82, 140, 227, 0.2);
}

.list-unidades-line > .button:before {
  width: 25px;
  height: 25px;
}

.list-unidades-line.loading {
  -webkit-box-shadow: 0 5px 8px rgba(180, 199, 227);
  box-shadow: 0 5px 8px rgba(180, 199, 227);
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(82, 140, 227, 0.2);
}

.preview-template {
  font-family: 'Courier New', Courier, monospace;
  font-size: 11pt;
  border: 1px solid #d3d8dc;
  border-radius: 5px;
  min-height: 300px;
}

.form-toggle-2 {
  display: -webkit-box;
  display: -ms-flexbox;
}

.form-toggle-2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
}

.form-toggle-2 span {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: #393042;
  opacity: 0.5;
  display: block;
  margin-bottom: 5px;
}

.form-toggle-2 label {
  cursor: pointer;
  display: inline-block;
  background: #fa3700;
  width: 80px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
}

.form-toggle-2 label:before {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 4px;
  -webkit-transition: all 150ms ease-out;
  -o-transition: all 150ms ease-out;
  transition: all 150ms ease-out;
}

.form-toggle-2 label:after {
  content: 'Não';
}

.form-toggle-2 input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form-toggle-2 input:checked + label {
  background: #00d07f;
}

.form-toggle-2 input:checked + label:before {
  right: 53px;
}

.form-toggle-2 input:checked + label:after {
  content: 'Sim';
  padding-left: 30px;
}

.form-toggle-2.is-disabled {
  pointer-events: none;
  cursor: default;
}

.form-toggle-2.is-disabled label,
.form-toggle-2.is-disabled input:checked + label {
  background: #b4c7e3;
}


@media (min-width: 768px) {
  .componente-react-mentions {
    display: grid;
    grid-template-columns: 50% 40% 10%;
  }

  .componente-react-mentions-modal {
    display: grid;
    grid-template-columns: 20% 60% 20%;
  }

  .componente-react-mentions-prescricao {
    display: grid;
    grid-template-columns: 50% 40% 10%;
    padding: 15px 0;
  }
}

@media (max-width: 767px) {

  .componente-react-mentions-modal,
  .componente-react-mentions-prescricao,
  .componente-react-mentions {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.componente-react-mentions-button {
  width: auto;
  background-color: rgba(82, 140, 227, 0.1);
  color: #528ce3;
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 0px 0px 0px 10px;
}

.componente-react-mentions-modal label,
.componente-react-mentions label {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: #393042;
  opacity: 0.5;
  display: block;
}

.componente-react-mentions-prescricao label {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.83;
  color: #393042;
  opacity: 0.5;
  display: block;
  align-items: center;
}

.time-location-line {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #74ad31;
  pointer-events: none;
}

.container-da-prescricao:not(:first-child) {
  border-top: solid 1px rgba(180, 199, 227, 0.23);
  margin-top: 15px;
  padding-top: 8px;
}

.info-agenda {
  color: #44c2ff;
  background-color: white;
  float: right;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  padding-left: 1px;
  padding-top: 3px;
}

.info-agenda .__react_component_tooltip {
  font-style: normal;
}

.excluir-telefone::before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font: 0/0 a;
  content: '\e814';
  font-family: fontello;
  font-size: 17px;
  background-color: rgba(250, 55, 0, 0.1);
  color: #fa3700;
  border-radius: 5px;
  border: solid 1px #fa3700;
  margin-top: 24px;
}

.box-agenda__timezone {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.83;
  color: #393042;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-self: baseline;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 60px;
}


.form[id^=formulario_Idas] r-grid,
.form[id^=formulario_Internações] r-grid,
.form[id^=formulario_Quedas] r-grid,
.form[id^=formulario_ITU] r-grid,
.form[id^=formulario_Anexos] r-grid {
  gap: 10px;
}

.modal-historico-de-prescricoes {
  width: 900px;
  padding: 0;
}

.fechar-historico-de-prescricoes {
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 12px 0px 18px 0px rgba(180, 199, 227, 0.35);
  border: 1px solid rgba(180, 199, 227, 0.23);
  z-index: 3;
}

.pesquisa-historico-de-prescricoes {
  padding: 20px 10px 10px 20px;
  background-color: #fff;
  box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
  border: 1px solid rgba(180, 199, 227, 0.23);
  z-index: 3;
}

.table-items-expanded-90__box-item {
  max-width: 90%;
  position: relative;
  margin: 12px 0;
  padding-left: 30px;
  padding-right: 16px;
}

.filtro-de-tarefas {
  grid-row-gap: 14px !important;
}

@media (max-width: 767px) {
  .filtro-de-tarefas {
    grid-template-columns: 1fr;
  }
}

.table-items-expanded__row.is-head {
  z-index: auto !important;
}

.pagination__item {
  display: flex;
}

.pagination__item span {
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.icon-alerta {
  position: absolute;
  top: 18px;
  right: 36px;
  z-index: 1;
  cursor: default;
}

.icon-alerta img {
  opacity: 0.7;
}

.form-group__field .icon-alerta,
.page-alterar-senha .icon-alerta {
  top: 12px;
}

.timeline {
  font-family: 'Roboto';
  font-size: 14px;
}

.timeline text {
  font-family: 'Roboto' !important;
}

.tooltip-timeline {
  width: 300px;
  font-family: 'Roboto';
  line-height: 1.20;
  margin: 5px;
}

.tooltip-timeline h2 {
  border-bottom: solid 1px #cfcfcf;
  font-size: 14px;
  padding-bottom: 5px;
}

.tooltip-timeline p {
  padding-top: 5px;
  font-size: 13px;
}

.form-search__input.filtro {
  padding-right: 75px;
}

.form-search__bt.filtrar {
  right: 42px;
  cursor: pointer;
}

.filtro-campo-pesquisa {
  left: -30px;
  margin-top: 10px;
  width: auto;
}

.user-doctor__specialty {
  line-height: 1.38;
}

.user-doctor__specialty.status {
  font-size: 9px;
}

.user-doctor__specialty.outros {
  font-style: italic;
  margin-top: 7px;
  cursor: pointer;
  font-size: 12px;
}

.__react_component_tooltip {
  padding: 8px 16px !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

.__react_component_tooltip.type-info {
  border: 1px solid #528ce3 !important;
  background: #fff !important;
  color: black !important;
}

.__react_component_tooltip.type-info.place-top::before {
  border-top-color: #528ce3 !important;
}

.__react_component_tooltip.type-info.place-bottom::before {
  border-bottom-color: #528ce3 !important;
}

.__react_component_tooltip.type-info.place-right::before {
  border-right-color: #528ce3 !important;
}

.__react_component_tooltip.type-info.place-left::before {
  border-left-color: #528ce3 !important;
}

.__react_component_tooltip.type-info.place-top::after {
  border-top-color: #fff !important;
}

.__react_component_tooltip.type-info.place-bottom::after {
  border-bottom-color: #fff !important;
}

.__react_component_tooltip.type-info.place-right::after {
  border-right-color: #fff !important;
}

.__react_component_tooltip.type-info.place-left::after {
  border-left-color: #fff !important;
}

.__react_component_tooltip.type-error {
  border: 1px solid #e74c3c !important;
  background: #fff !important;
  color: black !important;
}

.__react_component_tooltip.type-error.place-top::before {
  border-top-color: #e74c3c !important;
}

.__react_component_tooltip.type-error.place-bottom::before {
  border-bottom-color: #e74c3c !important;
}

.__react_component_tooltip.type-error.place-right::before {
  border-right-color: #e74c3c !important;
}

.__react_component_tooltip.type-error.place-left::before {
  border-left-color: #e74c3c !important;
}

.__react_component_tooltip.type-error.place-top::after {
  border-top-color: #fff !important;
}

.__react_component_tooltip.type-error.place-bottom::after {
  border-bottom-color: #fff !important;
}

.__react_component_tooltip.type-error.place-right::after {
  border-right-color: #fff !important;
}

.__react_component_tooltip.type-error.place-left::after {
  border-left-color: #fff !important;
}

.__react_component_tooltip.type-warning {
  border: 1px solid #F0AD4E !important;
  background: #fff !important;
  color: black !important;
}

.__react_component_tooltip.type-warning.place-top::before {
  border-top-color: #F0AD4E !important;
}

.__react_component_tooltip.type-warning.place-bottom::before {
  border-bottom-color: #F0AD4E !important;
}

.__react_component_tooltip.type-warning.place-right::before {
  border-right-color: #F0AD4E !important;
}

.__react_component_tooltip.type-warning.place-left::before {
  border-left-color: #F0AD4E !important;
}

.__react_component_tooltip.type-warning.place-top::after {
  border-top-color: #fff !important;
}

.__react_component_tooltip.type-warning.place-bottom::after {
  border-bottom-color: #fff !important;
}

.__react_component_tooltip.type-warning.place-right::after {
  border-right-color: #fff !important;
}

.__react_component_tooltip.type-warning.place-left::after {
  border-left-color: #fff !important;
}

.__react_component_tooltip.type-success {
  border: 1px solid #70C968 !important;
  background: #fff !important;
  color: black !important;
}

.__react_component_tooltip.type-success.place-top::before {
  border-top-color: #70C968 !important;
}

.__react_component_tooltip.type-success.place-bottom::before {
  border-bottom-color: #70C968 !important;
}

.__react_component_tooltip.type-success.place-right::before {
  border-right-color: #70C968 !important;
}

.__react_component_tooltip.type-success.place-left::before {
  border-left-color: #70C968 !important;
}

.__react_component_tooltip.type-success.place-top::after {
  border-top-color: #fff !important;
}

.__react_component_tooltip.type-success.place-bottom::after {
  border-bottom-color: #fff !important;
}

.__react_component_tooltip.type-success.place-right::after {
  border-right-color: #fff !important;
}

.__react_component_tooltip.type-success.place-left::after {
  border-left-color: #fff !important;
}

.filter-list__drop {
  z-index: 4;
}

/*NPS*/
.container-nps {
  max-width: 702px;
  margin: 0 auto;
  padding-block: 30px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.57;
}

@media (max-width: 767.98px) {
  .container-nps {
    padding: 0 20px;
  }
}

.container-nps h1 {
  font-size: 18px;
  font-weight: 500
}

.container-nps .pergunta p {
  color: #808080;
  padding-block: 10px;
}

.container-nps .form-label {
  font-size: 14px;
}

.container-nps .logo {
  display: flex;
  justify-content: center;
}

.container-nps .logo img {
  max-width: 232px;
  height: auto;
}

.container-nps .escala {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-radius: 4px;
}

.container-nps .escala .escala_nota {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
}

.container-nps .escala .escala_nota:not(:last-child) {
  margin-right: 2px;
}

.container-nps .escala .escala_nota.nao-selecionada {
  opacity: 0.5;
}

.container-nps .escala .escala_nota:hover,
.container-nps .escala .escala_nota.selecionada {
  border: 2px black solid;
}

.container-nps .botao-enviar {
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 16px;
  line-height: 1.57;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: #64b64d;
  border-radius: 5px;
  padding: 14px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  float: right;
  font-weight: 500;
}

.dashboard-de-tarefas {
  padding: 12px;
  background-color: #F1F3F4;
}

.box-panel__content {
  padding: 10px 5px 20px 10px;
}

.tarefas.page-configuracoes-empresa-dados .header-page-bar {
  align-items: flex-start;
}

.tarefas.page-configuracoes-empresa-dados .header-page-bar,
.tarefas .box-panel__content {
  padding: 0;
}

.filtros .header-page-tools .button.--plus-short {
  margin-right: 12px;
  margin-bottom: 12px;
}

.tarefas .form .fieldset-lista-de-tarefas {
  background-color: #ffff;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .tarefas .form .fieldset-lista-de-tarefas {
    padding: 0 12px 20px 12px;
  }
}

@media (max-width: 767.98px) {
  .tarefas .form .fieldset-lista-de-tarefas {
    padding: 0;
  }
}

.modal-vacinas h2 {
  font-size: 14px;
  text-justify: inter-word;
  line-height: 1.43;
  color: #393042;
}

.right-11 {
  right: -11% !important;
}

.bt-novo-bloqueio-agenda {
  font-size: 14px;
  text-align: center;
  min-width: 70px;
  background-color: rgba(82, 140, 227, 0.1);
  border: solid 1px #528ce3;
  color: #528ce3;
  padding: 10px;
  border-radius: 5px;
}

.escolher-assinador-digital-avatar {
  width: 114px;
  height: 54px;
  padding: 10px;
}

.escolher-assinador-digital {
  padding: 8px 13px 0;
  font-size: 14px;
  line-height: 1.83;
  letter-spacing: 1px;
  color: #92959d;
  text-align: left;
  font-weight: 400;
}

.escolher-unidade-user-avatar {
  width: 84px;
  height: 84px;
  border-radius: 100px;
  padding: 10px;
}

.table-items-expanded__row:hover,
.table-items-expanded__collapsable-cols:hover,
.table-items-expanded__collapsable-content:hover,
.table-items__row:hover {
  z-index: auto;
}

.table-items-expanded__tools {
  z-index: auto;
}

.gestao-de-condicoes-clinicas .lh-spam {
  line-height: 1.57;
}

.form-choice-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  border: 1px solid rgba(82, 140, 227, 0.2);
  border-radius: 5px;
  background-color: #EDF2F8;
  margin-bottom: 5px;
}

.form-choice-info + .form-choice-info {
  margin-top: 8px;
}

.form-choice-info__item {
  padding: 10px 15px;
  font-size: 13px;
  line-height: 1.69;
  color: #393042;
}

.form-choice-info__item:nth-child(1) {
  width: 5%;
  color: #528ce3
}

.form-choice-info__item:nth-child(2) {
  width: 95%;
  font-size: 14px;
}

.form-choice-info__item i {
  font-size: 18px;
}

.form-choice-info__item > p {
  margin-top: 10px;
}

.alerta-geral {
  z-index: 1;
  position: relative;
  min-height: 40px;
  background: #fa3a3a;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  color: #ffffff;
  padding-top: 8px;
}

.botao-copiar {
  font-size: 170%;
  float: right;
  cursor: pointer;
  position: relative;
  padding-right: 0;
  z-index: 1;
  vertical-align: super;
}

.botao-copiar:hover {
  color: #528ce3;
}

.tarefas .box-panel__sidebar__item:before,
.tarefas .box-panel__sidebar__item:hover:before,
.tarefas .box-panel__sidebar__item.is-selected:before {
  background-color: transparent;
}

.container-nps h2 {
  font-size: 30px;
  text-align: center;
  color: #685999;
}

.user-paciente-agenda-modal .foto-do-paciente {
  margin-right: 10px;
}

.user-paciente-agenda-modal .foto-do-paciente span {
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
}

.button.--automatico {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  line-height: 1.57;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: #616161;
  border-radius: 5px;
  padding: 14px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.5;
  pointer-events: none;
}


.checkbox input {
  border: 0px !important;
}

.checkbox.is-disabled input + label::before {
  background-color: rgba(97, 97, 97, 0.5);
}

.cabecalho-prescricoes-memed {
  padding: 20px 10px 10px 20px;
  background-color: #fff;
  box-shadow: 0px 12px 18px 0px rgba(180, 199, 227, 0.35);
  border: 1px solid rgba(180, 199, 227, 0.23);
  z-index: 3;
}

.modal-prescricoes-memed {
  width: 900px;
  padding: 0;
}

.fechar-prescricoes-memed {
  padding: 10px 0;
  background-color: rgb(247, 218, 218);
  box-shadow: 12px 0px 18px 0px rgba(180, 199, 227, 0.35);
  border: 1px solid rgba(180, 199, 227, 0.23);
  z-index: 3;
}

.info-prescricoes-memed {
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
}

.textarea-memed textarea {
  font-family: 'Courier New', Courier, monospace;
  background-color: rgba(230, 230, 230, 0.493);
  height: 300px;
  background-image: url(../img/memed.png);
  background-repeat: no-repeat;
  background-size: 5%;
  background-position-y: 95%;
  background-position-x: 98%;
}

.textarea-memed-assinada textarea {
  font-family: 'Courier New', Courier, monospace;
  background-color: rgba(230, 230, 230, 0.493);
  height: 300px;
  background-image: url(../img/memed-assinada.png);
  background-repeat: no-repeat;
  background-size: 7%;
  background-position-y: 95%;
  background-position-x: 98%;
}

.dashboard.relatorios .container {
  max-width: 1370px;
  margin: 0 auto;
  padding: 50px 30px;
}

.assinatura-digital-atendimento h2 {
  padding: 0 20px;
}

.assinatura-digital-atendimento__info {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 15px;
}

.page-validar-assinatura-digital {
  max-width: 580px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.page-validar-assinatura-digital .logo {
  background-image: url(../img/logo-blue.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  width: 130px;
  height: 40px;
  background-size: 100%;
  -ms-flex-item-align: center;
  align-self: center;
  margin-bottom: 40px;
}

.page-validar-assinatura-digital .box-campos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  color: #528ce3;
  height: 100vh;
}

.page-validar-assinatura-digital .box-campos > h1 {
  text-align: center;
}

.page-validar-assinatura-digital .box-campos > a,
.page-validar-assinatura-digital .box-campos > p {
  text-align: left;
}

.page-validar-assinatura-digital .box-campos > h1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
}

.page-validar-assinatura-digital .box-campos > p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  margin-top: 20px;
  color: #6b6b6b;
}

.page-validar-assinatura-digital .box-campos > a {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.43;
  color: #000000;
  margin-top: 10px;
}

.assinatura-digital-outro {
  width: 150px;
  text-align: center;
  background: #fff;
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 10px;
  font-weight: 500;
  color: rgba(57, 48, 66, 0.5);
  cursor: pointer;
}

.user-doctor-lote {
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  margin-top: 25px;
  border-radius: 10px;
  color: #528ce3;
  z-index: 2;
}

.user-doctor-lote.--erro {
  color: #e74c3c;
}

.box-panel__em-lote {
  max-height: 600px;
  overflow-y: auto;
}

.time-line-warning__bt {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 8px;
  right: 8px;
  text-align: center;
  line-height: 26px;
  padding: 0;
  border-radius: 5px;
  border: solid 1px #f1c40f;
  background-color: #fffbe6;
  font-size: 14px;
  color: #f1c40f;
  z-index: 9;
}

@media (max-width: 1024.98px) {
  .main-header .menu-account {
    margin-top: 30px;
  }
}

.dashboard.principal .container {
  max-width: 950px;
  margin: 0 auto;
  padding: 50px 30px;
}

.box-filter-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 820px) {
  .box-filter-custom {
    top: auto;
    right: 0;
    display: grid;
  }
}

@media (min-width: 451px) and (max-width: 650px) {
  .box-filter-custom {
    top: auto;
    right: 0;
    display: grid;
  }

  .date-period__before,
  .date-period__after {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  .date-period__content input {
    font-size: 14px;
    width: 210px;
    height: 15px;
  }
}

@media (max-width: 450px) {
  .page-configuracoes-agendamento .header-agendamento-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .box-filter-custom {
    top: auto;
    right: 0;
    display: grid;
  }

  .date-period__content input {
    font-size: 14px;
    width: 210px;
    height: 15px;
  }

  .date-period__before,
  .date-period__after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    background-color: #528ce3;
    padding: 0 3px;
    cursor: pointer;
  }
}

.componente-escala-ambiental {
  grid-row-gap: 0 !important;
}

.componente-escala-ambiental > r-cell:first-child {
  border-top: #000000 solid 1px;
}

.componente-escala-ambiental > r-cell {
  border-bottom: #000000 solid 1px;
  border-right: #000000 solid 1px;
  border-left: #000000 solid 1px;
}

.componente-escala-ambiental .componente {
  display: grid;
  grid-template-columns: 250px auto;
}

@media (max-width: 767.98px) {
  .componente-escala-ambiental .componente {
    display: grid;
    grid-template-columns: none;
  }
}

.componente-escala-ambiental .agrupamento,
.componente-escala-ambiental .pergunta,
.componente-escala-ambiental .pergunta-2n {
  padding: 8px;
  font-size: 14px;
}

.componente-escala-ambiental .agrupamento {
  text-align: center;
}

.componente-escala-ambiental .pergunta,
.componente-escala-ambiental .pergunta-2n {
  text-align: left;
}

.componente-escala-ambiental .resposta {
  font-style: italic;
}

.componente-escala-ambiental .cabecalho {
  background-color: #E1E0E1;
}

.componente-escala-ambiental .cabecalho div {
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.componente-escala-ambiental .cabecalho :not(:last-child) {
  border-right: #000000 solid 1px;
}

.componente-escala-ambiental .componente .perguntas > div:not(:last-child) {
  border-bottom: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-escala-ambiental .cabecalho {
    display: grid;
    grid-template-columns: none;
    background-color: #E1E0E1;
  }

  .componente-escala-ambiental .cabecalho :not(:last-child) {
    border-right: none;
  }
}

.componente-escala-ambiental .componente .respostas.leitura {
  display: grid;
  font-style: italic;
}

@media (max-width: 1024.98px) {
  .componente-escala-ambiental .componente .respostas {
    display: grid;
    grid-template-columns: none;
    font-style: italic;
  }
}

.componente-escala-ambiental .componente .pergunta:nth-child(2n+1) {
  background-color: #E1E0E1;
}

.componente-escala-ambiental .componente .pergunta-2n:nth-child(2n) {
  background-color: #E1E0E1;
}

.componente-escala-ambiental .componente > div:not(:last-child) {
  border-right: #000000 solid 1px;
}

.componente-escala-ambiental.data-leitura {
  font-size: 14px;
}

.componente-escala-ambiental .pergunta .respostas-leitura,
.componente-escala-ambiental .pergunta-2n .respostas-leitura {
  font-size: 14px;
  font-style: italic;
}

.base-color {
  color: #B4C7E3;
}

@media (min-width: 1025px) {
  .lista-de-espera {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'pacientes novo-paciente';
    margin-top: 15px;
  }

  .lista-de-espera .divider {
    padding-right: 5px;
    margin-right: 1.8em;
    border-right: solid 1px rgba(180, 199, 227, 0.3);
  }
}

.subtitle-lista-de-espera {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.22;
  color: #b4c7e3;
  margin-bottom: 10px;
}

.lista-de-espera__pacientes {
  overflow-y: scroll;
  max-height: 95%;
}

.lista-de-espera__pacientes__item {
  padding: 10px 10px 10px 30px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: #393042;
  transition: 0.3s background-color;
}

.lista-de-espera__pacientes__item + .lista-de-espera__pacientes__item {
  border-bottom: 1px solid #e8e8e8;
}

.lista-de-espera__pacientes__item + .lista-de-espera__pacientes__item {
  border-top: 1px solid #e8e8e8;
}

.lista-de-espera__pacientes__item__dados {
  display: flex;
}

.lista-de-espera__pacientes__item__nome {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.57;
  text-overflow: ellipsis;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
}

.lista-de-espera__pacientes__item__data {
  font-size: 14px;
  line-height: 1.57;
  color: rgba(57, 48, 66, 0.5);
}

.lista-de-espera__pacientes__item__acoes {
  display: flex;
  position: absolute;
  right: 0;
}

.lista-de-espera__pacientes__item__acoes .button {
  height: 32px;
  top: 8px;
  right: 8px;
  text-align: center;
  line-height: 1.57;
  padding: 5px;
  font-size: 13px;
  z-index: 9;
  align-items: center;
  justify-content: center;
  min-width: auto;
}

.lista-de-espera__pacientes__item__acoes > *:not(:first-child) {
  margin-left: 5px;
}

.lista-de-espera__pacientes__item__acoes .button .icon {
  margin-right: 0;
}

.lista-de-espera__pacientes__indicator {
  padding-left: 5px;
  line-height: 1.38;
  font-weight: bold;
  min-width: 20px;
  min-height: 20px;
  z-index: 0;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: solid 1px #528ce3;
  margin-right: 25px;
  background-color: #fff;
}

.lista-de-espera__pacientes__indicator-duplo-digito {
  padding-left: 1px;
}

@media (max-width: 1024.98px) {
  .tarefas .box-panel.--task .box-panel__content {
    width: 100%;
  }
}

.tags-filtros-tarefas {
  border-radius: 2px;
  display: inline-block;
}

.tag-filtros-tarefas {
  border: 1px solid #ddd;
  background: #fff;
  font-size: 14px;
  display: inline-block;
  padding: 6px 10px;
  cursor: move;
  color: #528ce3;
  border-radius: 5px;
  height: 32px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.tag-input-tarefas {
  display: none;
}

@media (max-width: 1024.98px) {
  .tab {
    overflow-x: auto;
    padding-bottom: 15px;
  }
}

.componente-fisioterapia {
  grid-row-gap: 0 !important;
}

.componente-fisioterapia > r-cell:first-child {
  border-top: #000000 solid 1px;
}

.componente-fisioterapia > r-cell {
  border-bottom: #000000 solid 1px;
  border-right: #000000 solid 1px;
  border-left: #000000 solid 1px;
}

.componente-fisioterapia .agrupamento {
  display: grid;
  grid-template-columns: auto;
}

.componente-fisioterapia .pergunta,
.componente-fisioterapia .opcoes-de-resposta {
  padding: 8px;
  font-size: 14px;
}

.componente-fisioterapia .pergunta {
  text-align: left;
}

.componente-fisioterapia .cabecalho {
  display: grid;
  grid-template-columns: auto;
  background-color: #E1E0E1;
}

.componente-fisioterapia .cabecalho div {
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.componente-fisioterapia .agrupamento p {
  padding: 8px 8px 0px 8px;
  font-size: 14px;
  font-weight: bold;
}


.componente-fisioterapia .cabecalho :not(:last-child) {
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-fisioterapia .cabecalho {
    display: grid;
    grid-template-columns: none;
    background-color: #E1E0E1;
  }

  .componente-fisioterapia .cabecalho .titulo-pontuacao {
    display: none;
  }

  .componente-fisioterapia .cabecalho :not(:last-child) {
    border-right: none;
  }
}

.componente-fisioterapia .agrupamento .form-label {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  opacity: 1;
}

.componente-fisioterapia .agrupamento .campo-numerico {
  width: 200px !important;
}

.componente-fisioterapia .agrupamento .dor {
  display: grid;
  grid-template-columns: 50% 50%;
}

.componente-fisioterapia .agrupamento .dor img {
  width: 400px;
  height: auto;
}

/* .componente-fisioterapia .agrupamento > div:not(:last-child) {
  border-right: #000000 solid 1px;
} */

.componente-fisioterapia .agrupamento:nth-child(2n+1) {
  background-color: #E1E0E1;
}

.componente-fisioterapia .resultado-questionario {
  display: grid;
  grid-template-columns: auto 100px;
  font-size: 14px;
}

.componente-fisioterapia .resultado-questionario > div:first-child {
  padding: 8px;
  text-align: left;
  border-right: #000000 solid 1px;
}

.componente-fisioterapia .resultado-questionario .legenda {
  font-size: 12px;
  font-style: italic;
  margin-top: 5px;
}

@media (max-width: 767.98px) {
  .componente-fisioterapia .resultado-questionario {
    display: grid;
    grid-template-columns: auto 100px;
    font-size: 14px;
  }

  .componente-fisioterapia .resultado-questionario > div:first-child {
    padding: 8px;
    text-align: right;
    border-right: none;
  }
}

.componente-fisioterapia .pergunta .respostas-leitura,
.componente-fisioterapia.paciente-leitura {
  font-size: 14px;
}


.pb-12 {
  padding-inline: 0 !important;
  padding-block: 12px !important;
}

.pi-12 {
  padding-inline: 12px !important;
  padding-block: 0 !important;
}

.p-12 {
  padding: 12px !important;
}

.lista-de-espera__pacientes__info {
  color: #528ce3;
  position: relative;
  z-index: 0;
  margin-right: 25px;
  background-color: #e7edf8;
  font-size: 11px;
  float: right;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-left: 2px;
  padding-top: 2px;
}

.lista-de-espera__pacientes__tooltip {
  background-color: #fff;
  z-index: 1;
}

.my-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.componente-select-botao {
  display: grid;
  grid-template-columns: 80% 20%;
}

.campo-conduta textarea {
  font-family: 'Courier New', Courier, monospace;
}

.button-light-novidades {
  width: auto;
  font-size: 12px;
  color: #92959d !important;
  padding-left: 4px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.page-novidades-rodape {
  width: 100%;
  padding: 0 0px;
  margin: 0 auto;
}

.page-novidades-rodape .logo {
  background-image: url(../img/logo-blue.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  -ms-flex-item-align: center;
  align-self: start;
  position: absolute;
  width: 130px;
  height: 40px;
  top: 30px;
  left: 30px;
}

.page-novidades-rodape .box-campos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
}

.page-novidades-rodape .box-campos > h1 {
  text-align: center;
  color: #6b6b6b;
}

.page-novidades-rodape .box-campos > h1 {
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1.5;
}

.page-novidades-rodape .box-campos > h2 {
  padding-top: 25px;
  padding-bottom: 15px;
  font-size: 2.8rem;
  font-weight: 400;
  color: #6b6b6b;
}

.page-novidades-rodape .box-campos > p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: #6b6b6b;
}

.page-novidades-rodape > a > img {
  align-self: flex-start;
  width: 440px;
  height: 247px;
  margin-right: 10px;
  border-radius: 10px;
  padding-top: 5px;
}

.page-novidades-rodape > p {
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.43;
  margin-top: 5px;
  padding-bottom: 5px;
  color: #6b6b6b;
}

.page-novidades-rodape > ul {
  list-style: square;
}

.page-novidades-rodape > ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.page-novidades-rodape > ul > li {
  line-height: 1.5;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.43;
  color: #6b6b6b;
}

.page-novidades-rodape .video-imagem {
  display: grid;
  grid-template-columns: 60% 40%;
}

.page-novidades-rodape .video-imagem .divider {
  -ms-flex-item-align: center;
  align-self: center;
  padding-right: 5px;
  margin-right: 1.8em;
  border-right: solid 1px rgba(180, 199, 227, 0.3);
}

.icon-close-modal-novidades {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 8px;
  right: 8px;
  text-align: center;
  line-height: 26px;
  padding: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  z-index: 9;
}

.novidades-youtube {
  width: 440px;
  height: 247px;
  border-radius: 30px 30px 30px 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.componente-mini-mental {
  grid-row-gap: 0 !important;
}

.componente-mini-mental > r-cell:first-child {
  border-top: #000000 solid 1px;
}

.componente-mini-mental > r-cell {
  border-bottom: #000000 solid 1px;
  border-right: #000000 solid 1px;
  border-left: #000000 solid 1px;
}

.componente-mini-mental .componente {
  display: grid;
  grid-template-columns: 250px 5fr 100px;
}

.componente-mini-mental .agrupamento,
.componente-mini-mental .pergunta,
.componente-mini-mental .pergunta-2n {
  padding: 8px;
  font-size: 14px;
}

.componente-mini-mental .agrupamento {
  text-align: center;
}

.componente-mini-mental .pergunta,
.componente-mini-mental .pergunta-2n {
  text-align: left;
}

.componente-mini-mental .resposta {
  font-style: italic;
}

.componente-mini-mental .cabecalho {
  display: grid;
  grid-template-columns: auto 100px;
  background-color: #E1E0E1;
}

.componente-mini-mental .cabecalho div {
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.componente-mini-mental .cabecalho :not(:last-child) {
  border-right: #000000 solid 1px;
}

.componente-mini-mental .componente .perguntas > div:not(:last-child) {
  border-bottom: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-mini-mental .cabecalho {
    display: grid;
    grid-template-columns: none;
    background-color: #E1E0E1;
  }

  .componente-mini-mental .cabecalho .titulo-pontuacao {
    display: none;
  }

  .componente-mini-mental .cabecalho :not(:last-child) {
    border-right: none;
  }
}

.componente-mini-mental .componente .respostas.leitura {
  display: grid;
  font-style: italic;
}

@media (max-width: 1024.98px) {
  .componente-mini-mental .componente .respostas {
    display: grid;
    grid-template-columns: none;
    font-style: italic;
  }
}

.componente-mini-mental .componente .pergunta:nth-child(2n+1) {
  background-color: #E1E0E1;
}

.componente-mini-mental .componente .pergunta-2n:nth-child(2n) {
  background-color: #E1E0E1;
}

.componente-mini-mental .componente > div:not(:last-child),
.componente-mini-mental .perguntas {
  border-right: #000000 solid 1px;
}

.componente-mini-mental .resultado-questionario {
  display: grid;
  grid-template-columns: auto 100px;
  font-size: 14px;
  background-color: #E1E0E1;
}

.componente-mini-mental .resultado-questionario > div:first-child {
  padding: 8px;
  text-align: center;
  border-right: #000000 solid 1px;
}

@media (max-width: 767.98px) {
  .componente-mini-mental .resultado-questionario {
    display: grid;
    grid-template-columns: 250px 5fr;
    font-size: 14px;
  }

  .componente-mini-mental .componente {
    display: grid;
    grid-template-columns: none;
    border-right: none;
  }

  .componente-mini-mental .resultado-questionario > div:first-child {
    padding: 8px;
    text-align: center;
    border-right: none;
    border-right: #000000 solid 1px;
  }
}

.componente-mini-mental .pergunta .respostas-leitura,
.componente-mini-mental .pergunta-2n .respostas-leitura,
.componente-mini-mental.paciente-leitura {
  font-size: 14px;
  font-style: italic;
}

.componente-mini-mental .pergunta .respostas-leitura span,
.componente-mini-mental .pergunta-2n .respostas-leitura span {
  margin-top: 5px;
  display: inline-block;
}

.pentagono {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.checkbox input + label {
  line-height: 1.15;
  margin-top: 3px;
}

.z-index-10 {
  z-index: 10 !important;
}

.texto-sublinhado {
  text-decoration: underline;
}

.dia-atual-destaque {
  background-color: lightgray !important;
}

.feriado-destaque {
  background-color: rgba(82, 140, 227, 0.2);
}

/*Pedido de contato*/
.page-pedido-de-contato .logo {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.page-pedido-de-contato .logo img {
  max-width: 232px;
  height: auto;
}

.page-pedido-de-contato .button.--light {
  color: #430593;
  border: solid 2px #430593;
}

.page-pedido-de-contato .button.--primary {
  background-color: #430593;
}

.page-pedido-de-contato h1 {
  font-size: 30px;
  color: #430593;
  margin-top: 50px;
  margin-bottom: 20px;
}

.page-pedido-de-contato h2 {
  font-size: 18px;
  font-weight: normal;
  color: #7a7a7a;
}

.page-pedido-de-contato h3 {
  font-size: 16px;
  font-weight: normal;
  color: #7a7a7a;
  margin-top: 15px;
}

.page-pedido-de-contato fieldset {
  border-radius: 12px;
}

@media (max-width: 1024.98px) {
  .page-pedido-de-contato h1 {
    margin: 0;
  }
}

.page-pedido-de-contato .containers {
  display: flex;
  flex-direction: row;
  flex-wrap: initial;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.69;
  margin: 20px;
}

@media (max-width: 767.98px) {
  .page-pedido-de-contato .containers {
    flex-direction: column;
    align-items: center;
  }
}

.page-pedido-de-contato .containers .informacao {
  max-width: 650px;
  width: 50%;
  padding: 20px;
}

.page-pedido-de-contato .containers .informacao .telefone {
  display: flex;
  align-items: center;
  margin-block: 15px;
  margin-left: 15px;
}

.page-pedido-de-contato .containers .informacao .telefone strong {
  margin-left: 15px;
}

.page-pedido-de-contato .containers .informacao .telefone .urgencia {
  display: flex;
  flex-direction: column;
}

.page-pedido-de-contato .containers .formulario {
  max-width: 650px;
  width: 50%;
}

@media (max-width: 1024.98px) {
  .page-pedido-de-contato .containers .informacao {
    width: 100%;
  }

  .page-pedido-de-contato .containers .formulario {
    width: 100%;
  }
}

.page-pedido-de-contato .containers .formulario r-grid {
  grid-row-gap: 14px !important;
}

.calendario-agenda-bloqueio {
  background-image: -o-repeating-linear-gradient(135deg, #f3f3f3, #f3f3f3 16px, #e5e5e5 16px, #e5e5e5 18px);
  background-image: repeating-linear-gradient(-45deg, #f3f3f3, #f3f3f3 16px, #e5e5e5 16px, #e5e5e5 18px);
}

.tooltip-botoes {
  display: inline-flex;
}

.scroll {
  scroll-margin-top: 150px;
}

.text-align-center {
  text-align: center;
}

.unity-infos__item .teleconsulta {
  margin-bottom: 0 !important;
}

.pd-top-10 {
  padding-top: 10px;
}

.foto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
}

.foto .foto-image {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 5px;
  overflow: hidden;
}

.foto .foto-image img {
  width: 110px;
  height: 110px;
}

@media (min-width: 1025px) {
  .foto .foto-image:hover .foto-image-tools {
    opacity: 1;
    visibility: visible;
  }
}

.foto .foto-image-tools {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 110px;
  height: 110px;
  top: 0;
  left: 0;
  background-color: rgba(57, 48, 66, 0.5);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.foto .foto-image-zoom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: fontello;
  content: '\e80d';
  display: block;
  font-size: 15px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin: 0 4px;
}

@media (min-width: 1025px) {
  .foto .foto-image-zoom:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media (max-width: 767.98px) {
  .foto {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 575.98px) {
  .foto .button {
    width: 100%;
  }
}

.triagem {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
  gap: 10px;
}

.cabecalho-triagem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  background-color: #ffff;
  border-radius: 5px;
}

.cabecalho-triagem .identificacao {
  flex-grow: 1;
}

.cabecalho-triagem .identificacao .nome {
  font-weight: 500;
  font-size: 18px;
}

.cabecalho-triagem .identificacao .sexo-e-idade {
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
}

.cabecalho-triagem .identificacao .data-de-nascimento {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
}

.cabecalho-triagem .dados-de-atendimento {
  flex-grow: 1;
}

.cabecalho-triagem .sem-convenio,
.cabecalho-triagem .sem-programa {
  padding: 8px;
  background-color: #fee2e2;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}

.cabecalho-triagem .convenio,
.cabecalho-triagem .programa {
  padding: 8px;
  background-color: #bae6fd;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
}

.cabecalho-triagem .programa,
.cabecalho-triagem .sem-programa {
  margin-top: 8px;
}

.cabecalho-triagem .resultado {
  width: 250px;
}

.cabecalho-triagem .dados-de-atendimento .enfermeiro,
.cabecalho-triagem .dados-de-atendimento .medico {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
}

.cabecalho-triagem .resultado {
  margin-left: 10px;
  width: 160px;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
}

.cabecalho-triagem .resultado {
  background-color: grey;
}

.cabecalho-triagem .resultado.amarelo {
  background-color: #ba8b00;
}

.cabecalho-triagem .resultado.verde {
  background-color: #357D55;
}

.cabecalho-triagem .resultado.vermelho {
  background-color: #A92F0F;
}

.cabecalho-triagem .resultado .descricao-da-cor {
  font-size: 18px;
}

.cabecalho-triagem .resultado .tooltip__drop {
  top: 115px;
  right: -50px;
}

.cabecalho-triagem .resultado .tooltip__drop__description {
  text-align: left;
  padding-right: 10px;
}

.cabecalho-triagem .resultado .conteudo-tooltip {
  padding-top: 10px;
}

.cabecalho-triagem .resultado .tooltip__drop {
  height: 400px;
}

.cabecalho-triagem .resultado .acoes-tooltip {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  justify-content: flex-end;
}

.triagem .conteudo-triagem {
  flex: 1;
  display: flex;
  gap: 10px;
  background-color: #ffff;
  border-radius: 5px;
  padding: 10px;
}

.triagem .conteudo-triagem .sidebar {
  width: 250px;
  height: 100%;
}

.triagem .conteudo-triagem .box-panel__sidebar {
  width: 100%;
}

.triagem .conteudo-triagem .perguntas {
  flex: 1;
  height: 100%;
}

.pergunta-container {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  font-family: 'Roboto', sans-serif;
}

.texto-pergunta {
  font-size: 14px;
  color: #333;
  margin-bottom: 12px;
}

.botoes-resposta {
  display: flex;
  gap: 8px;
}

.botoes-resposta button {
  background-color: #ffffff;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.botoes-resposta button:hover {
  background-color: #007bff;
  color: #ffffff;
}

.botoes-resposta button.selecionado {
  background-color: #66b2ff;
  color: #ffffff;
}

.lista-itens {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lista-itens label {
  font-size: 14px;
  color: #333;
}

.escala-intensidade {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.escala-intensidade label {
  font-size: 14px;
  color: #333;
}

.input-escala {
  flex: 1;
}

.valor-escala {
  font-size: 14px;
  color: #007bff;
  font-weight: bold;
}

textarea {
  width: 100%;
  height: 80px;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  resize: vertical;
}

.escala-intensidade .input-range {
  width: 50%;
  font-size: 14px;
}

.escala-intensidade .input-range .input-range__label-container {
  color: #333;
  font-size: 12px;
}

.escala-intensidade .input-range .input-range__label--value {
  top: -2rem;
  font-weight: 700;
}

.secao {
  width: 100%;
}

.triagem .acoes-triagem {
  flex: 1;
  display: flex;
  gap: 10px;
  background-color: #ffff;
  border-radius: 5px;
  padding: 10px;
  justify-content: flex-end;
}

.triagem .box-panel__sidebar__items {
  height: 100%;
}

.btn-alterar-resultado-triagem {
  height: 30px;
  margin-top: 5px;
  padding: 5px !important;
  margin-left: 10px;
}

.alerta-de-justificativa-preenchida {
  font-weight: normal;
}

.triagem .form-choice-alerta {
  margin-bottom: 0;
}

.button-remover-justificativa-triagem {
  background-color: #f7f7f7;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  padding-right: 8px;
  padding-left: 8px;
  color: #444;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Arial, sans-serif;
}

.button-remover-justificativa-triagem:hover {
  background-color: #e0e0e0;
}

.pergunta-container.is-disabled,
.pergunta-container .is-disabled input[type="checkbox"],
.botoes-resposta button:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.telefone-whatsapp {
  display: flex;
  gap: 5px;
}

.inline-flex {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.timeline-container {
  display: flex;
  justify-content: center;
  padding: 25px;
}

.timeline {
  position: relative;
  width: 100%;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}

.timeline-event {
  position: relative;
  text-align: center;
  z-index: 1;
}

.timeline-event .dot {
  margin-top: -6px;
  width: 10px !important;
  height: 10px !important;
  min-width: 10px !important;
  min-height: 10px !important;
  cursor: pointer;
}

.dados-atendimento {
  border-color: #528ce3;
}

.dados-ida-ao-pa {
  border-color: #fa8c16;
}

.dados-internacao {
  border-color: #e74c3c;
}

.dados-vacina {
  border-color: #52c41a;
}

.dados-atendimento:hover {
  background-color: #528ce3;
}

.dados-ida-ao-pa:hover {
  background-color: #fa8c16;
}

.dados-internacao:hover {
  background-color: #e74c3c;
}

.dados-vacina:hover {
  background-color: #52c41a;
}

.timeline .table-items__row:not(.is-head){
  border: 1px solid #e8e8e8;
}

.timeline-indicadores {
  border-right: 1px solid #e8e8e8;
}

.timeline-indicadores .indicador {
  font-size: 14px;
  line-height: 1.57;
  color: #393042;
  font-weight: bold;
}

.timeline-indicadores .evento {
  font-size: 12px;
  line-height: 1.57;
  color: #393042;
}

.atendimentos, .vacinas, .idas-ao-pa, .internacoes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.atendimentos .icones, .dados,
.vacinas .icones, .dados,
.idas-ao-pa .icones, .dados,
.internacoes .icones, .dados {
  flex: 1;
  text-align: center;
}

.atendimentos .icones,
.vacinas .icones,
.idas-ao-pa .icones,
.internacoes .icones {
  margin-left: 10px;
}

.atendimentos:hover {
  background-color: rgba(82, 140, 227, 0.6);
  border-radius: 4px;
}

.vacinas:hover {
  background-color: rgba(82, 196, 26, 0.6);
  border-radius: 4px;
}

.idas-ao-pa:hover {
  background-color: rgba(250, 140, 22, 0.6);
  border-radius: 4px;
}

.internacoes:hover {
  background-color: rgba(231, 76, 60, 0.6);
  border-radius: 4px;
}

.month-divider-wide {
  width: 1px;
  height: 150px;
  background-color: rgba(128, 128, 128, 0.1);
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.meses-descricao {
  position: absolute;
  padding-top: 10px;
  z-index: 0;
  font-size: 10px;
  color: rgba(128, 128, 128, 0.6);
}

.numero-eventos {
  position: absolute;
  color: black;
  font-weight: bold;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  color: #333;
  z-index: 1;
}

.csap-info {
  display: flex;
  align-items: flex-end;
}

.csap-link:hover {
  color: #528ce3;
}

.resultado-triagem-titulo,
.box-panel__sidebar__item .resultado-timeline {
  text-transform: uppercase;
  position: absolute;
  padding: 5px;
  width: 90px;
  /*height: 24px;*/
  top: 8px;
  right: 8px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  z-index: 9;
}

.resumo-triagem pre {
  margin-top: 5px;
}

.resumo-triagem .form-title {
  color: #528ce3;
  margin-top: 12px;
}

.resumo-triagem .description strong {
  font-weight: 500;
}

.resumo-triagem .description .intensidade {
  font-weight: 700;
}

.informacao-adicional {
  font-size: 12px;
  font-style: italic;
}

.descricao {
  font-size: 10px !important;
  font-style: italic !important;
  margin-left: 25px !important;
  padding: 0 !important;
  font-weight: normal !important;
}

.leitura-descricao {
  margin-left: 20px !important;
}

.box-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 !important;
  border: 1px solid #528ce3;
  border-radius: 5px;
  background-color: rgba(82, 140, 227, 0.2);
  margin-bottom: 5px;
}

.box-info + .box-info {
  margin-top: 4px;
}

.box-info__item {
  padding: 0px !important;;
  font-size: 13px;
  line-height: 1.69;
  color: #393042;
}

.box-info__item:nth-child(1) {
  width: 3% !important;
  color: #528ce3
}

.box-info__item:nth-child(2) {
  width: 97% !important;
  padding: 0 !important;
  margin-left: 4px;
  font-size: 12px !important;
}

.box-info__item i {
  font-size: 18px;
}

.box-info__item > p {
  padding: 0 !important;
  margin-top: 5px;
}

.box-info__item.--full:nth-child(1) {
  width: 100%;
}

.box-alerta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 !important;
  border: 1px solid #ffe58f;
  border-radius: 5px;
  background-color: #fffbe6;
  margin-bottom: 5px;
}

.box-alerta + .box-alerta {
  margin-top: 4px;
}

.box-alerta__item {
  padding: 0px !important;
  font-size: 13px;
  line-height: 1.69;
  color: #393042;
}

.box-alerta__item:nth-child(1) {
  width: 3% !important;
  color: #f1c40f
}

.box-alerta__item:nth-child(2) {
  width: 97% !important;
  padding: 0 !important;
  margin-left: 4px;
  font-size: 12px !important;
}

@media (max-width: 575.98px) {
  .box-info__item:nth-child(2) {
    margin-left: 12px;
  }
  .box-alerta__item:nth-child(2) {
    margin-left: 12px;
  }
}

.box-alerta__item i {
  font-size: 18px;
}

.box-alerta__item > p {
  padding: 0 !important;
  margin-top: 5px;
}

.box-alerta__item.--full:nth-child(1) {
  width: 100%;
}

.segunda-linha-auto-complete-pacientes.tags-diagnostico {
  display: grid;
  grid-template-columns: 100% !important;
}